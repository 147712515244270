import { AddCircleOutline, Description as DescriptionIcon, Newspaper } from "@mui/icons-material";
import LibraryBooks from "@mui/icons-material/LibraryBooks";
import { default as Menu } from "@mui/icons-material/Menu";
import PlaylistAddCheck from "@mui/icons-material/PlaylistAddCheck";
import PlaylistRemove from "@mui/icons-material/PlaylistRemove";
import SettingsIcon from "@mui/icons-material/Settings";
import Plagiarism from "@mui/icons-material/Plagiarism";
import {
  Alert,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Snackbar,
  Typography,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import { forwardRef } from "react";
import { Outlet, Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import { Profile } from "../auth/Profile";
import { PreviewDrawer } from "../components/drawers/PreviewDrawer";
import { useAppStore } from "../session/store";
import { PreviewDrawerProvider, usePreviewDrawerContext } from "../context/PreviewDrawerContext";
import { ToolsProvider } from "../context/ToolsContext";

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== "open" })(
  ({ theme, open }) => ({
    "& .MuiDrawer-paper": {
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: "border-box",
      ...(!open && {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

interface ListItemLinkProps {
  icon?: React.ReactElement;
  primary: string;
  to?: string;
  onClick?: () => void;
}

const Link = forwardRef<HTMLAnchorElement, RouterLinkProps>(function Link(itemProps, ref) {
  return <RouterLink ref={ref} {...itemProps} role={undefined} />;
});

const ListItemLink = (props: ListItemLinkProps) => {
  const { icon, primary, to, onClick } = props;

  if (!!to && !onClick) {
    return (
      <li>
        <ListItemButton component={Link} to={to}>
          {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
          <ListItemText primary={primary} />
        </ListItemButton>
      </li>
    );
  }

  return (
    <li>
      <ListItemButton onClick={onClick}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItemButton>
    </li>
  );
};

export const LayoutComponent = () => {
  const { snackbarState, hideAlert } = useAppStore();
  const { togglePreviewDrawer } = usePreviewDrawerContext();

  const handleSnackbarClose = (_: React.SyntheticEvent | Event, reason: string) => {
    if (snackbarState?.ignoreClickaway && reason === "clickaway") return;
    hideAlert();
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbarState?.open}
        onClose={handleSnackbarClose}
        autoHideDuration={snackbarState?.duration}
      >
        <Alert
          onClose={snackbarState?.ignoreClickaway ? undefined : () => hideAlert()}
          severity={snackbarState?.severity}
          sx={{ width: "100%" }}
          action={snackbarState?.action}
        >
          {snackbarState?.message}
        </Alert>
      </Snackbar>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}>
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          <Newspaper sx={{ mr: 1 }} />
          <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            Brochure Data Manager
          </Typography>
          <Profile />
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={true} sx={{ paddingTop: "65px" }}>
        <List component="nav">
          <ListItemLink
            onClick={() => togglePreviewDrawer()}
            primary="Preview"
            icon={<AddCircleOutline />}
          />
          <Divider sx={{ my: 1 }} />
          <ListItemLink to="/general" primary="General" icon={<Menu />} />
          <ListItemLink to="/benefits" primary="Benefits" icon={<PlaylistAddCheck />} />
          <ListItemLink
            to="/limits-exclusions"
            primary="Limits & Exclusions"
            icon={<PlaylistRemove />}
          />
          <Divider sx={{ my: 1 }} />
          <ListItemLink to="/products" primary="Products" icon={<LibraryBooks />} />
          <ListItemLink
            to="/conditional-mappings"
            primary="Conditional Mappings"
            icon={<Plagiarism />}
          />
          <ListItemLink to="/tools" primary="Tools" icon={<SettingsIcon />} />
          <ListItemLink to="/logs" primary="Logs" icon={<DescriptionIcon />} />
        </List>
      </Drawer>
      <PreviewDrawer />
      <Box
        component="main"
        sx={{
          backgroundColor: theme =>
            theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                <Outlet />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export const Layout = () => {
  return (
    <ToolsProvider>
      <PreviewDrawerProvider>
        <LayoutComponent />
      </PreviewDrawerProvider>
    </ToolsProvider>
  );
};
