import { Box, Drawer, Grid } from "@mui/material";
import { PreviewTabs } from "../previews/PreviewTabs";
import { usePreviewDrawerContext } from "../../context/PreviewDrawerContext";
import { previewDrawerSx } from "../constants";

export const PreviewDrawer = () => {
  const { previewDrawerOpen, togglePreviewDrawer } = usePreviewDrawerContext();

  return (
    <Drawer
      anchor="left"
      open={previewDrawerOpen}
      onClose={togglePreviewDrawer}
      sx={previewDrawerSx}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <PreviewTabs />
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
};
