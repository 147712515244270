import { Button, Grid } from "@mui/material";
import { useForm, useFormState } from "react-hook-form";
import { useUpdateGeneral, useUpdateGeneralBatch } from "../../../hooks/useGeneral";
import { General as GeneralModel } from "../../../types/brochure";
import { GeneralForm } from "../base/GeneralForm";
import { objNullsToStrings } from "../../../utils/models.utils";
import { SaveConfirmDialog } from "../../dialogs/SaveConfirmDialog";
import { useMgmtDrawer } from "../../../context/MgmtDrawerContext";
import { bodyForBatchRequest } from "../../../utils/request.utils";
import { EditStateSelect } from "../fields/EditStateSelect";
import { useAppStore } from "../../../session/store";
import { GridRowParams } from "@mui/x-data-grid-pro";

export const EditGeneral = ({
  selectedRow,
}: {
  selectedRow: GridRowParams<GeneralModel> | null;
}) => {
  const { control, handleSubmit } = useForm<GeneralModel>({
    defaultValues: objNullsToStrings(selectedRow?.row ?? {}),
  });
  const { dirtyFields } = useFormState({ control });
  const { toggleMgmtDrawer, checkedStates, toggleSaveConfirmDialog, saveFieldsMode } =
    useMgmtDrawer();
  const { mutate: updateGeneral } = useUpdateGeneral();
  const { mutate: updateGeneralBatch } = useUpdateGeneralBatch();
  const showAlert = useAppStore(state => state.showAlert);

  const onSuccess = () => {
    toggleMgmtDrawer();
    showAlert("General updated successfully", "success");
  };
  const onError = () => {
    toggleMgmtDrawer();
    showAlert("General update failed", "error");
  };
  const onSubmit = handleSubmit(data => {
    if (checkedStates.length === 1) {
      updateGeneral(data, { onSuccess, onError });
    } else {
      const batchBody = bodyForBatchRequest(data, checkedStates, saveFieldsMode, dirtyFields);
      updateGeneralBatch(batchBody, { onSuccess, onError });
    }
  });

  return (
    <>
      <h3>Edit General Variable</h3>
      <Grid container spacing={2}>
        <GeneralForm control={control} />
        <Grid item xs={12}>
          <EditStateSelect />
        </Grid>
        <Grid item xs={12}>
          <div style={{ display: "flex", gap: 10, justifyContent: "space-between" }}>
            <Button
              type="submit"
              variant="contained"
              onClick={() => {
                if (checkedStates.length === 1) return onSubmit();
                toggleSaveConfirmDialog();
              }}
            >
              Save
            </Button>
          </div>
        </Grid>
      </Grid>
      <SaveConfirmDialog handleSave={onSubmit} control={control} />
    </>
  );
};
