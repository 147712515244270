import { Add as AddIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  Fab,
  Grid,
} from "@mui/material";
import { GridRowParams, GridToolbar } from "@mui/x-data-grid-pro";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { drawerSx, fabSx } from "../components/constants";
import { getColumns } from "../components/datagrids/ConditionalMappingColumns";
import { PersistedDataGrid } from "../components/datagrids/PersistedDataGrid";
import { AddConditionalMapping } from "../components/forms/add/AddConditionalMapping";
import { EditConditionalMapping } from "../components/forms/edit/EditConditionalMapping";
import { Header } from "../components/pages/Header";
import { MgmtDrawerProvider, useMgmtDrawer } from "../context/MgmtDrawerContext";
import {
  useDeleteConditionalMapping,
  useListConditionalMappings,
} from "../hooks/useConditionalMappings";
import { useAppStore } from "../session/store";
import { ConditionalMapping as ConditionalMappingModel } from "../types/brochure";
import { objNullsToStrings } from "../utils/models.utils";

export const ConditionalMappingsManagement = () => {
  const { isOpen, modMode, selectedRow, toggleMgmtDrawer, updateModMode, updateSelectedRow } =
    useMgmtDrawer();
  const methods = useForm<ConditionalMappingModel>();
  const { reset } = methods;
  const { data: rows, isLoading } = useListConditionalMappings();
  const { mutate: deleteConditionalMapping } = useDeleteConditionalMapping();
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const showAlert = useAppStore(state => state.showAlert);

  const deleteOnSuccess = () => showAlert("Conditional Mapping deleted", "success");
  const deleteOnError = () => showAlert("Conditional Mapping delete failed", "error");

  const handleEditClick = (params: GridRowParams<ConditionalMappingModel>) => {
    updateSelectedRow(params);
    toggleMgmtDrawer();
    updateModMode("edit");
    reset(objNullsToStrings(params.row));
  };

  const handleAddClick = () => {
    toggleMgmtDrawer();
    updateModMode("add");
    reset();
  };

  const handleDeleteClick = (params: GridRowParams<ConditionalMappingModel>) => {
    updateSelectedRow(params);
    setDeleteConfirmOpen(!deleteConfirmOpen);
  };

  const handleConfirmDelete = () => {
    if (selectedRow) {
      deleteConditionalMapping(selectedRow.row, {
        onSuccess: deleteOnSuccess,
        onError: deleteOnError,
      });
      updateSelectedRow(null);
    }
    setDeleteConfirmOpen(false);
  };

  const columns = getColumns(handleEditClick, handleDeleteClick);

  return (
    <FormProvider {...methods}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3} justifyContent="center">
          <Header title="Conditional Mappings" />
          <Grid item xs={12} md={12} sx={{ minHeight: 350, width: "100%" }}>
            <PersistedDataGrid
              name="conditions"
              rows={rows ?? []}
              columns={columns}
              loading={isLoading}
              slots={{ toolbar: GridToolbar }}
              initialState={{ pinnedColumns: { right: ["actions"] } }}
            />
          </Grid>
        </Grid>
      </Box>
      <Drawer anchor="right" open={isOpen} onClose={toggleMgmtDrawer} sx={drawerSx}>
        {modMode === "add" ? <AddConditionalMapping /> : <EditConditionalMapping />}
      </Drawer>
      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(!deleteConfirmOpen)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete {selectedRow?.row.title}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(!deleteConfirmOpen)} autoFocus>
            Cancel
          </Button>
          <Button type="button" color="error" variant="contained" onClick={handleConfirmDelete}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Fab color="primary" aria-label="add" sx={fabSx} onClick={handleAddClick}>
        <AddIcon />
      </Fab>
    </FormProvider>
  );
};

export const ConditionalMappings = () => {
  return (
    <MgmtDrawerProvider<ConditionalMappingModel>>
      <ConditionalMappingsManagement />
    </MgmtDrawerProvider>
  );
};
