import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import API from "../services/api";
import { Benefit, Product, State } from "../types/brochure";
import { useAppStore } from "../session/store";
import { BatchRequest, DuplicateRequest, ReorderRequest } from "../types/api";

const QUERY_KEY_LIST_BENEFITS = "listBenefits";

const getListBenefits = async (product?: Product, state?: State) => {
  if (!product?.id || !state?.id) {
    return Promise.resolve([]);
  }
  return API.services.getListBenefits(state, product);
};

export const useListBenefits = () => {
  const appStore = useAppStore();
  const { product, state } = appStore;
  return useQuery<Benefit[], Error>(
    [QUERY_KEY_LIST_BENEFITS, product?.id, state?.id],
    () => getListBenefits(product, state),
    {
      enabled: !!product?.id && !!state?.id,
    },
  );
};

export const useAddBenefit = () => {
  const appStore = useAppStore();
  const { product, state } = appStore;
  const queryClient = useQueryClient();
  return useMutation<Benefit, Error, Benefit>(
    (body: Benefit) =>
      API.services.postBenefit({ ...body, product_id: product?.id, state_id: state?.id }),
    {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_LIST_BENEFITS, product?.id, state?.id],
        });
      },
    },
  );
};

export const useUpdateBenefit = () => {
  const appStore = useAppStore();
  const { product, state } = appStore;
  const queryClient = useQueryClient();
  return useMutation<Benefit, Error, Benefit>(
    (body: Benefit) =>
      API.services.putBenefit({ ...body, product_id: product?.id, state_id: state?.id }),
    {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_LIST_BENEFITS, product?.id, state?.id],
        });
      },
    },
  );
};

export const useUpdateBenefitBatch = () => {
  const appStore = useAppStore();
  const { product, state } = appStore;
  const queryClient = useQueryClient();
  return useMutation<Benefit, Error, BatchRequest<Benefit>>(
    ({ body, states }) =>
      API.services.postBenefitBatch(
        { ...body, product_id: product?.id, state_id: state?.id },
        states,
      ),
    {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_LIST_BENEFITS, product?.id],
        });
      },
    },
  );
};

export const useDeleteBenefit = () => {
  const appStore = useAppStore();
  const { product } = appStore;
  const queryClient = useQueryClient();
  return useMutation<Benefit, Error, Benefit>(
    (body: Benefit) => API.services.deleteBenefit(Number(body.id)),
    {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_LIST_BENEFITS, product?.id],
        });
      },
    },
  );
};

export const useReorderBenefit = () => {
  const appStore = useAppStore();
  const { product } = appStore;
  const queryClient = useQueryClient();
  return useMutation<Benefit, Error, ReorderRequest<Benefit>>(
    ({ body, oldIndex, targetIndex }) => API.services.reorderBenefit(body, oldIndex, targetIndex),
    {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_LIST_BENEFITS, product?.id],
        });
      },
    },
  );
};

const duplicateBenefits = async ({ product, fromState, toStates }: DuplicateRequest) => {
  if (!product?.id || !fromState?.id) {
    console.log("no product or fromState", product, fromState);
    return Promise.resolve(undefined);
  }
  return API.services.duplicateBenefits(fromState, toStates, product);
};

export const useDuplicateBenefits = () => {
  const { product } = useAppStore();
  const queryClient = useQueryClient();
  return useMutation<Benefit | undefined, Error, DuplicateRequest>(req => duplicateBenefits(req), {
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_LIST_BENEFITS, product?.id],
      });
    },
  });
};
