import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
} from "@mui/material";
import { states } from "../../constants";
import { useListProducts } from "../../hooks/useProducts";
import { useAppStore } from "../../session/store";
import { PropsWithChildren } from "react";

export const SkeletonWrapper = ({
  isLoading,
  children,
}: { isLoading: boolean } & PropsWithChildren) => {
  return isLoading ? <Skeleton variant="rounded" height={40} /> : <>{children}</>;
};

export const ProductStateSelect = () => {
  const { data: products, isLoading } = useListProducts();

  const appStore = useAppStore();

  const handleStateChange = (event: SelectChangeEvent<string>) => {
    const state = states.find(state => state.name === event.target.value);
    if (state) appStore.updateState(state);
  };

  const handleProductChange = (event: SelectChangeEvent<string>) => {
    const product = products?.find(product => product.name === event.target.value);
    if (product) appStore.updateProduct(product);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <SkeletonWrapper isLoading={isLoading}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">State</InputLabel>
              <Select
                labelId="state-select"
                id="state-select"
                value={appStore?.state?.name}
                label="State"
                onChange={handleStateChange}
              >
                {states.map(state => (
                  <MenuItem key={state.id} value={state.name}>
                    {state.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </SkeletonWrapper>
        </Grid>
        <Grid item xs={2}>
          <SkeletonWrapper isLoading={isLoading}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Product</InputLabel>
              <Select
                labelId="product-select"
                id="product-select"
                value={appStore?.product?.name}
                label="Product"
                onChange={handleProductChange}
                disabled={isLoading}
              >
                {products?.map(product => (
                  <MenuItem key={product.id} value={product.name}>
                    {product.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </SkeletonWrapper>
        </Grid>
      </Grid>
    </>
  );
};
