import { GridState, Product, State } from "../types/brochure";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { AlertType, SnackbarPosition } from "../types/app";
import { Variable } from "../types/lambda";

type AppStore = {
  product?: Product;
  state?: State;
  updateProduct: (newProduct: Product) => void;
  updateState: (newState: State) => void;
  gridStates: GridState[];
  updateGridState: (gridState: GridState) => void;
  getGridState: (name: string) => GridState | undefined;
  showAlert: (
    msg: string,
    severity: AlertType,
    duration?: number | null,
    action?: React.ReactNode,
    ignoreClickaway?: boolean,
  ) => void;
  hideAlert: () => void;
  snackbarState?: {
    open: boolean;
    message: string;
    severity: AlertType;
    duration?: number | null;
    action?: React.ReactNode;
    ignoreClickaway?: boolean;
  };
  generateBrochureInput?: string;
  updateGenerateBrochureInput: (input: string) => void;
  renderPreviewData?: Variable[];
  updateRenderPreviewData: (data: Variable[]) => void;
};

const defaultValue = {
  product: {
    id: 1,
    name: "CI22K",
  },
  state: {
    id: 1,
    name: "Alabama",
  },
  gridStates: [],
};

export const useAppStore = create<AppStore>()(
  devtools(
    persist(
      (set, get) => ({
        product: defaultValue.product,
        state: defaultValue.state,
        updateProduct: (newProduct: Product) => set(state => ({ ...state, product: newProduct })),
        updateState: (newState: State) => set(state => ({ ...state, state: newState })),
        gridStates: defaultValue.gridStates,
        updateGridState: (gridState: GridState) => {
          set(stateData => ({
            ...stateData,
            gridStates: [
              ...stateData.gridStates?.filter(grid => grid.name !== gridState.name),
              gridState,
            ],
          }));
        },
        getGridState: (name: string): GridState | undefined => {
          const states = get().gridStates;
          return states?.find((state: GridState) => state.name === name);
        },
        getGenerateBrochureInput: (): string => {
          return get().generateBrochureInput || "";
        },
        updateGenerateBrochureInput: (input: string) => {
          set(state => ({ ...state, generateBrochureInput: input }));
        },
        showAlert: (
          msg: string,
          severity: AlertType,
          duration = 5000,
          action?: React.ReactNode,
          ignoreClickaway?: boolean,
        ) => {
          set(state => ({
            ...state,
            snackbarState: {
              open: true,
              message: msg,
              severity,
              duration,
              action,
              ignoreClickaway,
            },
          }));
        },
        hideAlert: () => {
          set(state => ({
            ...state,
            snackbarState: {
              open: false,
              message: state.snackbarState?.message || "",
              severity: state.snackbarState?.severity || "info",
            },
          }));
        },
        updateRenderPreviewData: (data: Variable[]) => {
          set(state => ({ ...state, renderPreviewData: data }));
        },
      }),
      {
        name: "brochure-client-store",
      },
    ),
  ),
);
