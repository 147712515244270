import { TextField } from "@mui/material";
import { Control, Controller, RegisterOptions, useFormState } from "react-hook-form";

type RulesType = Omit<
  RegisterOptions<any>,
  "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
>;

export const ControlledTextField = ({
  control,
  field,
  fieldLabel,
  helperText,
  rules,
  disabled,
}: {
  field: string;
  control: Control<any>;
  fieldLabel?: string;
  helperText?: string;
  rules?: RulesType;
  disabled?: boolean;
}) => {
  const label =
    fieldLabel ??
    field.replace(/_/g, " ").replace(/\w\S*/g, w => w.replace(/^\w/, c => c.toUpperCase()));
  const inputLabelProps = { shrink: true };
  const { dirtyFields } = useFormState({ control });
  return (
    <Controller
      control={control}
      rules={rules}
      render={({ field: { onChange, onBlur, value }, fieldState: { error, invalid } }) => {
        const dirty = !!dirtyFields[field];
        return (
          <TextField
            label={label}
            size="small"
            onBlur={onBlur}
            onChange={onChange}
            value={value ?? ""}
            InputLabelProps={inputLabelProps}
            multiline
            fullWidth
            color={dirty ? "secondary" : "primary"}
            focused={dirty}
            helperText={error ? error.message : helperText}
            error={invalid}
            disabled={disabled}
          />
        );
      }}
      name={field}
    />
  );
};
