import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, Grid, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Control, Controller, useFieldArray, useWatch } from "react-hook-form";
import { ConditionalMapping } from "../../../types/brochure";
import { camelCaseToWords } from "../../../utils/forms.utils";
import { ControlledTextField } from "../fields/ControlledTextField";

export const ConditionalMappingValuesForm = ({
  control,
}: {
  control: Control<ConditionalMapping>;
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "values",
    keyName: "uuid",
  });

  const type = useWatch({ control, name: "type" });

  return (
    <Controller
      control={control}
      render={_ => (
        <Box>
          {fields?.map(({ id, data, name }, index) => {
            return (
              <Accordion defaultExpanded={id === undefined}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  {id ? name : "New Mapping Value"}
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <ControlledTextField
                        fieldLabel="Name"
                        field={`values[${index}].name`}
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Name is required",
                          },
                        }}
                      />
                    </Grid>
                    {type !== "tiered" && (
                      <Grid item xs={12}>
                        <ControlledTextField
                          fieldLabel={type === "suffix" ? "Suffix" : "Type Value"}
                          field={`values[${index}].type_value`}
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: "Suffix is required",
                            },
                          }}
                        />
                      </Grid>
                    )}
                    {Object.entries(data).map(([key]) => {
                      const name = camelCaseToWords(key as string);
                      const helperText =
                        key === "singleText"
                          ? "The value to display when {value} is unique"
                          : "The value to display with all {value}'s are the same; uses value specified in first mapping value";
                      return (
                        <Grid item xs={12}>
                          <ControlledTextField
                            key={key}
                            fieldLabel={name}
                            field={`values[${index}].data.${key}`}
                            control={control}
                            helperText={helperText}
                            disabled={key === "joinedText" && index !== 0}
                            rules={{
                              required: {
                                value: true,
                                message: `${name} is required`,
                              },
                            }}
                          />
                        </Grid>
                      );
                    })}
                    <Grid item xs={12}>
                      <Typography variant="subtitle2" color="gray">
                        {"Use {value} to reference the input variable values"}
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionDetails>
                <AccordionActions>
                  <Button onClick={() => remove(index)} color="error">
                    Remove
                  </Button>
                </AccordionActions>
              </Accordion>
            );
          })}
          <Button
            onClick={() =>
              append({
                name: "",
                type_value: "",
                data: {
                  singleText: "",
                  joinedText: fields[0]?.data.joinedText ?? "",
                },
              })
            }
          >
            + Add Mapping Value
          </Button>
        </Box>
      )}
      name="values"
    />
  );
};
