import { Grid } from "@mui/material";
import { Control } from "react-hook-form";
import { Brochure } from "../../../types/brochure";
import { QuillCodeField } from "../fields/QuillCodeField";

export const BrochureForm = ({ control }: { control: Control<Brochure> }) => {
  return (
    <Grid item xs={12}>
      <QuillCodeField label="EPS Payload" field="payload" control={control} />
    </Grid>
  );
};
