import { Box, Button, Grid } from "@mui/material";
import { CopyProductsDialog } from "../components/dialogs/CopyProductsDialog";
import { Header } from "../components/pages/Header";
import { useToolsContext } from "../context/ToolsContext";

export const Tools = () => {
  const { setMode, toggleDialog } = useToolsContext();

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <Header title="Tools" />
        <Grid item xs={12} sx={{ justifyContent: "center" }}>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              setMode("copyProduct");
              toggleDialog();
            }}
          >
            Copy Products to Environment
          </Button>
        </Grid>
      </Box>
      <CopyProductsDialog />
    </div>
  );
};
