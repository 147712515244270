import { ErrorResponseBody } from "../types/api";
import { SaveFieldsMode } from "../types/drawer";
import { Variable, TableVariable, Row, Header, SubHeaderRow } from "../types/lambda";

export const isSaveFieldsMode = (value: string): value is SaveFieldsMode =>
  ["all", "edited"].includes(value);

export const isErrorResponse = (value: any): value is ErrorResponseBody =>
  value && value.errorType && value.errorMessage;

export const isTableVariable = (variable: Variable): variable is TableVariable =>
  variable.type === "table";

export const isHeaderRow = (row?: Row): row is Header => !!row && "title" in row && !!row.title;

export const isSubHeaderRow = (row?: Row): row is SubHeaderRow =>
  !!row && "subHeader" in row && !!row.subHeader;
