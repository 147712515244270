import { ArrowRightAlt } from "@mui/icons-material";
import { Grid, Alert, Button } from "@mui/material";
import { usePreviewDrawerContext } from "../../context/PreviewDrawerContext";

export const NoDataPanel = () => {
  const { changePreviewTab } = usePreviewDrawerContext();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Alert severity="info">
          Please add EPS data to generate a preview. Click the button below to add EPS data.
        </Alert>
      </Grid>
      <Grid item xs={12}>
        <Button variant="outlined" endIcon={<ArrowRightAlt />} onClick={() => changePreviewTab(1)}>
          Add EPS Data
        </Button>
      </Grid>
    </Grid>
  );
};
