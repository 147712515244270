import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { UseMutateFunction } from "@tanstack/react-query";
import { useMgmtDrawer } from "../../context/MgmtDrawerContext";
import { useAppStore } from "../../session/store";

type Props<T> = {
  type: "Benefits" | "Limits & Exclusions" | "General";
  updateFn: UseMutateFunction<T, unknown, T, unknown>;
  loading: boolean;
};

export const DeleteDialog = <T,>({ type, updateFn, loading }: Props<T>) => {
  const { deleteConfirmIsOpen, updateSelectedRow, toggleDeleteConfirmDialog, selectedRow } =
    useMgmtDrawer();
  const showAlert = useAppStore(state => state.showAlert);

  const onClose = () => {
    toggleDeleteConfirmDialog();
  };

  const deleteOnSuccess = () => showAlert(`${type} deleted`, "success");
  const deleteOnError = () => showAlert(`${type} delete failed`, "error");

  const handleDelete = () => {
    if (selectedRow) {
      updateFn(selectedRow.row, { onSuccess: deleteOnSuccess, onError: deleteOnError });
      updateSelectedRow(null);
    }
    toggleDeleteConfirmDialog();
  };

  return (
    <Dialog
      open={deleteConfirmIsOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete {selectedRow?.row.name}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus>
          Cancel
        </Button>
        <Button
          type="button"
          color="error"
          variant="contained"
          onClick={handleDelete}
          disabled={loading}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
