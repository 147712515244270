import { DataGridPro, DataGridProProps, useGridApiRef } from "@mui/x-data-grid-pro";
import { useCallback, useEffect, useLayoutEffect } from "react";
import { useAppStore } from "../../session/store";

export interface PersistedDataGridProps extends DataGridProProps {
  name: string;
}

export const PersistedDataGrid = (props: PersistedDataGridProps) => {
  const gridStates = useAppStore(state => state.gridStates);
  const updateGridState = useAppStore(state => state.updateGridState);
  const apiRef = useGridApiRef();
  const { name } = props;

  const saveSnapshot = useCallback(() => {
    if (!apiRef?.current?.exportState) return;
    const { pagination, ...rest } = apiRef.current.exportState();
    updateGridState({
      name,
      gridState: rest,
    });
  }, [apiRef, updateGridState, name]);

  useLayoutEffect(() => {
    window.addEventListener("beforeunload", saveSnapshot);
    return () => {
      window.removeEventListener("beforeunload", saveSnapshot);
      saveSnapshot();
    };
  }, [saveSnapshot]);

  useEffect(() => {
    if (!apiRef.current) return;
    const initState = gridStates?.filter(grid => grid.name === props.name)?.[0]?.gridState ?? {};
    const initialState = Object.assign({}, props.initialState, initState);
    apiRef.current.restoreState(initialState);
  }, [apiRef, gridStates, props.initialState, props.name]);

  return <DataGridPro apiRef={apiRef} {...props} initialState={props.initialState} />;
};
