import { AppState, Auth0Provider, Auth0ProviderOptions } from "@auth0/auth0-react";
import { LicenseInfo } from "@mui/x-license-pro";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { PropsWithChildren, memo } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import "./App.scss";
import { ProtectedRoute } from "./auth/ProtectedRoute";
import { ConfigProvider } from "./context/ConfigContext";
import { Config, useConfig } from "./hooks/useConfig";
import { Benefits } from "./pages/Benefits";
import { General } from "./pages/General";
import { Layout } from "./pages/Layout";
import { LimitsExclusions } from "./pages/LimitsExclusions";
import { Products } from "./pages/Products";
import { AuthProvider } from "./context/AuthContext";
import { Tools } from "./pages/Tools";
import { Logs } from "./pages/Logs";
import { ConditionalMappings } from "./pages/ConditonalMappings";

LicenseInfo.setLicenseKey(
  "13387c36cda6497472764928946739eaTz03NjgzNyxFPTE3MjkxOTgwNjcwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=",
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<ProtectedRoute component={Layout} />}>
      <Route index element={<Benefits />} />
      <Route path="benefits" element={<Benefits />} />
      <Route path="limits-exclusions" element={<LimitsExclusions />} />
      <Route path="general" element={<General />} />
      <Route path="products" element={<Products />} />
      <Route path="tools" element={<Tools />} />
      <Route path="logs" element={<Logs />} />
      <Route path="conditional-mappings" element={<ConditionalMappings />} />
      <Route path="*" element={<NoMatch />} />
    </Route>
  </Routes>
);

const Auth0CallbackProvider = ({ children, ...props }: PropsWithChildren<Auth0ProviderOptions>) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState?: AppState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

const Core = memo(
  ({ config }: { config: Config }) => (
    <ConfigProvider config={config}>
      <Auth0CallbackProvider
        cacheLocation="localstorage"
        domain={config.auth.domain ?? ""}
        clientId={config.auth.clientId ?? ""}
        authorizationParams={{
          audience: config.auth.audience ?? "",
          organization: config.auth.organization ?? "",
          redirectUri: window.location.origin,
        }}
      >
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <AppRoutes />
            <ReactQueryDevtools initialIsOpen={false} />
          </AuthProvider>
        </QueryClientProvider>
      </Auth0CallbackProvider>
    </ConfigProvider>
  ),
  (prevProps, nextProps) => JSON.stringify(prevProps.config) === JSON.stringify(nextProps.config),
);

const ConfiguredApp = () => {
  const { config } = useConfig();
  if (!config) return null;
  return <Core config={config} />;
};

export default function App() {
  return (
    <BrowserRouter>
      <ConfiguredApp />
    </BrowserRouter>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Page not found</h2>
    </div>
  );
}
