import { Add as AddIcon } from "@mui/icons-material";
import { Box, Drawer, Fab, Grid } from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid-pro";
import { drawerSx, fabSx } from "../components/constants";
import { getColumns } from "../components/datagrids/GeneralColumns";
import { PersistedDataGrid } from "../components/datagrids/PersistedDataGrid";
import { DeleteDialog } from "../components/dialogs/DeleteDialog";
import { DuplicateDialog } from "../components/dialogs/DuplicateDialog";
import { AddGeneral } from "../components/forms/add/AddGeneral";
import { EditGeneral } from "../components/forms/edit/EditGeneral";
import { Header } from "../components/pages/Header";
import { ProductStateSelect } from "../components/pages/ProductStateSelect";
import { MgmtDrawerProvider, useMgmtDrawer } from "../context/MgmtDrawerContext";
import { useEventHandlers } from "../hooks/useEventHandlers";
import { useDeleteGeneral, useDuplicateGenerals, useListGenerals } from "../hooks/useGeneral";
import { General as GeneralModel } from "../types/brochure";

export const GeneralsManagement = () => {
  const { isOpen, modMode, selectedRow, toggleMgmtDrawer, toggleDupConfirmDialog } =
    useMgmtDrawer();
  const { data: rows, isLoading } = useListGenerals();
  const { mutate: deleteGeneral, isLoading: deleteGeneralLoading } = useDeleteGeneral();
  const { mutate: duplicateGenerals, isLoading: duplicateLoading } = useDuplicateGenerals();
  const { handleAddClick, handleDeleteClick, handleDupRowClick, handleEditClick } =
    useEventHandlers<GeneralModel>();

  const columns = getColumns(handleEditClick, handleDeleteClick, handleDupRowClick);

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3} justifyContent="center">
          <Header
            title="General"
            handleDupClick={toggleDupConfirmDialog}
            duplicateEnabled={!!rows?.length}
          />
          <Grid item xs={12} md={12} sx={{ width: "100%" }}>
            <ProductStateSelect />
          </Grid>
          <Grid item xs={12} md={12} sx={{ minHeight: 350, width: "100%" }}>
            <PersistedDataGrid
              name="generals"
              rows={rows ?? []}
              columns={columns}
              loading={isLoading}
              slots={{ toolbar: GridToolbar }}
              initialState={{ pinnedColumns: { right: ["actions"] } }}
            />
          </Grid>
        </Grid>
      </Box>
      <Drawer anchor="right" open={isOpen} onClose={toggleMgmtDrawer} sx={drawerSx}>
        {modMode === "add" ? <AddGeneral /> : <EditGeneral selectedRow={selectedRow} />}
      </Drawer>
      <DeleteDialog type="General" updateFn={deleteGeneral} loading={deleteGeneralLoading} />
      <Fab color="primary" aria-label="add" sx={fabSx} onClick={handleAddClick}>
        <AddIcon />
      </Fab>
      <DuplicateDialog type="General" updateFn={duplicateGenerals} loading={duplicateLoading} />
    </div>
  );
};

export const General = () => {
  return (
    <MgmtDrawerProvider<GeneralModel>>
      <GeneralsManagement />
    </MgmtDrawerProvider>
  );
};
