import { Select, MenuItem, FormControl, InputLabel, FormHelperText } from "@mui/material";
import { Control, Controller } from "react-hook-form";

export const ControlledSelectField = ({
  control,
  field,
  fieldLabel,
  options,
  helperText,
}: {
  control: Control<any>;
  field: string;
  fieldLabel?: string;
  options: string[];
  helperText?: string;
}) => {
  const label =
    fieldLabel ??
    field.replace(/_/g, " ").replace(/\w\S*/g, w => w.replace(/^\w/, c => c.toUpperCase()));
  return (
    <Controller
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControl fullWidth size="small">
          <InputLabel id={`${label}-select`}>{label}</InputLabel>
          <Select
            size="small"
            labelId={`${label}-select`}
            value={value ?? ""}
            label={label}
            name={field}
            onChange={onChange}
          >
            {options.map(option => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      )}
      name={field}
    />
  );
};
