import { Button, Grid } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { useAddBenefit } from "../../../hooks/useBenefits";
import { Benefit } from "../../../types/brochure";
import { BenefitForm } from "../base/BenefitForm";
import { useAppStore } from "../../../session/store";
import { useMgmtDrawer } from "../../../context/MgmtDrawerContext";
import { AddAnotherCheckbox } from "../fields/AddAnotherCheckbox";
import { objNullsToStrings } from "../../../utils/models.utils";
import { useMemo } from "react";

export const AddBenefit = () => {
  const { toggleMgmtDrawer, addAnother, selectedRow } = useMgmtDrawer();
  const defaultValues = useMemo(() => {
    if (!selectedRow) return {};
    const { id, ...rest } = selectedRow.row;
    return objNullsToStrings(rest);
  }, [selectedRow]);
  const methods = useForm<Benefit>({ defaultValues });
  const { reset, handleSubmit, control } = methods;
  const { mutate: addBenefit } = useAddBenefit();
  const showAlert = useAppStore(state => state.showAlert);

  const onSuccess = () => {
    showAlert("Benefit added successfully", "success");
    if (addAnother) return;
    reset({
      input: "",
      node: "",
      title: "",
      subtitle: "",
      value: "",
      description: "",
      months: "",
      months_reference: "",
      days: "",
      days_reference: "",
      amount: "",
      amount_reference: "",
      start_time: "",
      start_time_reference: "",
      end_time: "",
      end_time_reference: "",
      years: "",
      years_reference: "",
      conditional_mappings: [],
    });
    toggleMgmtDrawer();
  };
  const onError = () => showAlert("Adding Benefit failed", "error");
  const onSubmit = handleSubmit(data => addBenefit(data, { onSuccess, onError }));

  return (
    <>
      <h3>Add Benefit Variable</h3>
      <Grid container spacing={2}>
        <FormProvider {...methods}>
          <BenefitForm control={control} />
        </FormProvider>
        <AddAnotherCheckbox />
        <Grid item xs={12}>
          <Button type="submit" variant="contained" onClick={onSubmit}>
            Add
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
