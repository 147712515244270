import { Button, Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useMgmtDrawer } from "../../../context/MgmtDrawerContext";
import { useAddConditionalMapping } from "../../../hooks/useConditionalMappings";
import { useAppStore } from "../../../session/store";
import { ConditionalMapping } from "../../../types/brochure";
import { ConditionalMappingForm } from "../base/ConditionalMappingForm";

export const AddConditionalMapping = () => {
  const { handleSubmit, reset } = useFormContext<ConditionalMapping>();
  const { mutate: addConditionalMapping } = useAddConditionalMapping();
  const showAlert = useAppStore(state => state.showAlert);
  const { toggleMgmtDrawer } = useMgmtDrawer();

  const onSuccess = () => {
    reset({
      name: "",
      type: "suffix",
      values: [],
    });
    showAlert("Conditional Mapping added successfully", "success");
    toggleMgmtDrawer();
  };
  const onError = () => showAlert("Adding Conditional Mapping failed", "error");
  const onSubmit = handleSubmit(data => addConditionalMapping(data, { onSuccess, onError }));

  return (
    <>
      <h3>Add Conditional Mapping</h3>
      <Grid container spacing={2}>
        <ConditionalMappingForm />
        <Grid item xs={12}>
          <Button type="submit" variant="contained" onClick={onSubmit}>
            Add
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
