import { GridColDef } from "@mui/x-data-grid";
import { GridActionsCellItem, GridRowParams } from "@mui/x-data-grid-pro";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { LimitExclusion } from "../../types/brochure";
import styles from "./QuillColumn.module.scss";

export const getColumns = (
  handleEditClick: (row: GridRowParams<LimitExclusion>) => void,
  handleDeleteClick: (row: GridRowParams<LimitExclusion>) => void,
  handleDupRowClick: (row: GridRowParams<LimitExclusion>) => void,
): GridColDef[] => {
  return [
    { field: "input", headerName: "Input", flex: 1, minWidth: 80, sortable: false },
    { field: "node", headerName: "Node", flex: 1, minWidth: 80, sortable: false },
    { field: "name", headerName: "Name", flex: 1, minWidth: 80, sortable: false },
    {
      field: "text",
      headerName: "Text",
      flex: 1,
      minWidth: 80,
      sortable: false,
      cellClassName: styles.quillCell,
      renderCell: params => (
        <div
          dangerouslySetInnerHTML={{
            __html: params.value,
          }}
        />
      ),
    },
    { field: "months", headerName: "Months", flex: 1, minWidth: 80, sortable: false },
    {
      field: "months_reference",
      headerName: "Months Reference",
      flex: 1,
      minWidth: 80,
      sortable: false,
    },
    {
      field: "actions",
      type: "actions",
      getActions: params => [
        <GridActionsCellItem
          key={`${params.id}-edit`}
          icon={<EditIcon />}
          label="Edit"
          onClick={() => handleEditClick(params)}
        />,
        <GridActionsCellItem
          key={`${params.id}-dup`}
          icon={<ContentCopyIcon />}
          label="Duplicate"
          onClick={() => handleDupRowClick(params)}
        />,
        <GridActionsCellItem
          key={`${params.id}-delete`}
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => handleDeleteClick(params)}
        />,
      ],
    },
  ];
};
