import FormLabel from "@mui/material/FormLabel";
import classNames from "classnames";
import "quill/dist/quill.snow.css";
import { Control, Controller, useFormState } from "react-hook-form";
import ReactQuill from "react-quill";
import styles from "./QuillTextField.module.scss";

export const QuillTextField = ({
  label,
  field,
  selectedRow,
  control,
}: {
  label: string;
  field: string;
  control: Control<any>;
  selectedRow?: boolean;
}) => {
  const disabled = selectedRow !== undefined && !selectedRow;
  const { dirtyFields } = useFormState({ control });
  const modules = {
    toolbar: [{ list: "bullet" }, "bold"],
  };

  return (
    <>
      <FormLabel
        className={
          dirtyFields[field]
            ? classNames([styles.formLabel, styles.dirtyFormLabel])
            : styles.formLabel
        }
      >
        {label}
      </FormLabel>
      <div
        className={
          dirtyFields[field]
            ? classNames([styles.container, styles.dirtyContainer])
            : styles.container
        }
      >
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <ReactQuill
              theme="snow"
              value={value}
              onChange={onChange}
              modules={modules}
              readOnly={disabled}
            />
          )}
          name={field}
        />
      </div>
    </>
  );
};
