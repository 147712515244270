import { PictureAsPdf } from "@mui/icons-material";
import { Grid, Button } from "@mui/material";

export const OptionButtons = ({
  onGeneratePdf,
  onExportToPdf,
  isGenerateBrochureLoading,
}: {
  onGeneratePdf: () => void;
  onExportToPdf: () => void;
  isGenerateBrochureLoading: boolean;
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item>
        <Button
          type="submit"
          variant="contained"
          onClick={onGeneratePdf}
          disabled={isGenerateBrochureLoading}
          startIcon={<PictureAsPdf />}
        >
          Generate InDesign PDF
        </Button>
      </Grid>
      <Grid item>
        <Button
          type="button"
          variant="outlined"
          onClick={onExportToPdf}
          startIcon={<PictureAsPdf />}
        >
          Export Preview to PDF
        </Button>
      </Grid>
    </Grid>
  );
};
