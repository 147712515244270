import { Alert, AlertColor, Box, CircularProgress, Grid, Link, Tab, Tabs } from "@mui/material";
import { Margin, usePDF } from "react-to-pdf";
import { useGenerateBrochure } from "../../hooks/useBrochure";
import { useRenderHtmlPreview } from "../../hooks/useRenderHtmlPreview";
import { useAppStore } from "../../session/store";
import { cleanTags } from "../../utils/html.utils";
import { getBrochureRepoUrl } from "../../utils/urls.utils";
import { isErrorResponse } from "../../utils/validation.utils";
import { TabPanel, a11yProps } from "../tabs/TabPanel";
import { InfoTable } from "./InfoTable";
import { NoDataPanel } from "./NoDataPanel";
import { OptionButtons } from "./OptionButtons";
import { OrderData } from "./OrderData";
import { HtmlRenderer } from "./RenderPreview";
import { usePreviewDrawerContext } from "../../context/PreviewDrawerContext";
import styles from "./Previews.module.scss";

export const PreviewTabs = () => {
  const appStore = useAppStore();
  const { generateBrochureInput, showAlert } = appStore;
  const { previewTab, changePreviewTab } = usePreviewDrawerContext();
  const { mutate: generateBrochure, isLoading: isGenerateBrochureLoading } = useGenerateBrochure();
  const {
    variableGroups,
    orderInfo,
    error: htmlError,
    isLoading: isHtmlPreviewLoading,
  } = useRenderHtmlPreview();

  const getAlertMsg = (type: AlertColor, message?: unknown) => {
    if (type === "success") return "Brochure generation has begun.";
    if (isErrorResponse(message)) return message.errorMessage;
    if (typeof message === "string") return message;
    return "An error occurred, please try again";
  };

  const onGeneratePdfSuccess = () => {
    const brochureRepoUrl = getBrochureRepoUrl();
    showAlert(
      getAlertMsg("success"),
      "success",
      5000,
      <Link className={styles.link} href={brochureRepoUrl}>
        View Brochure Repository
      </Link>,
    );
  };

  const onGeneratePdf = () => {
    if (!generateBrochureInput) return;
    const json = JSON.parse(cleanTags(generateBrochureInput));
    try {
      generateBrochure(json, {
        onError: error => {
          showAlert(getAlertMsg("error", error), "error");
        },
        onSuccess: onGeneratePdfSuccess,
      });
    } catch (e) {
      showAlert(getAlertMsg("error", "Invalid JSON"), "error");
    }
  };

  const { toPDF, targetRef } = usePDF({
    filename: `PREVIEW-${orderInfo?.planId}-${orderInfo?.preparedFor}-${orderInfo?.product}-${orderInfo?.state}.pdf`,
    page: { margin: Margin.MEDIUM },
  });
  const onExportToPdf = () => toPDF();

  return (
    <>
      <Box className={styles.tabsContainer}>
        <Tabs
          value={previewTab}
          onChange={(_, value) => changePreviewTab(value)}
          aria-label="Previews"
        >
          <Tab label="Preview" {...a11yProps(0)} />
          <Tab label="EPS Data" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={previewTab} index={0}>
        {isHtmlPreviewLoading && <CircularProgress />}
        {htmlError && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Alert severity="error">{htmlError}</Alert>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          {!variableGroups && !htmlError && !isHtmlPreviewLoading && <NoDataPanel />}
          {variableGroups && !htmlError && !isHtmlPreviewLoading && (
            <>
              <Grid container spacing={3}>
                <Grid item xs={9}>
                  <InfoTable orderInfo={orderInfo} />
                </Grid>
                <Grid item xs={3}>
                  <OptionButtons
                    onGeneratePdf={onGeneratePdf}
                    onExportToPdf={onExportToPdf}
                    isGenerateBrochureLoading={isGenerateBrochureLoading}
                  />
                </Grid>
              </Grid>
              <HtmlRenderer targetRef={targetRef} variableGroups={variableGroups} />
            </>
          )}
        </Grid>
      </TabPanel>
      <TabPanel value={previewTab} index={1}>
        <OrderData />
      </TabPanel>
    </>
  );
};
