import { Button, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { useAddProduct } from "../../../hooks/useProducts";
import { Product } from "../../../types/brochure";
import { ProductForm } from "../base/ProductForm";
import { useAppStore } from "../../../session/store";
import { useMgmtDrawer } from "../../../context/MgmtDrawerContext";

export const AddProduct = () => {
  const { reset, handleSubmit, control } = useForm<Product>({ defaultValues: { ftp: false } });
  const { mutate: addProduct } = useAddProduct();
  const showAlert = useAppStore(state => state.showAlert);
  const { toggleMgmtDrawer } = useMgmtDrawer();

  const onSuccess = () => {
    reset({
      name: "",
      data_examples: {},
    });
    showAlert("Product added successfully", "success");
    toggleMgmtDrawer();
  };
  const onError = () => showAlert("Adding Product failed", "error");
  const onSubmit = handleSubmit(data => addProduct(data, { onSuccess, onError }));

  return (
    <>
      <h3>Add Product</h3>
      <Grid container spacing={2}>
        <ProductForm control={control} />
        <Grid item xs={12}>
          <Button type="submit" variant="contained" onClick={onSubmit}>
            Add
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
