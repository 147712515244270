import { useCallback, useEffect, useState } from "react";
import { useAppStore } from "../session/store";
import { Variable, VariableGroups } from "../types/lambda";
import { useParseData } from "./useTools";
import { OrderInfo } from "../types/brochure";
import { usePreviewDrawerContext } from "../context/PreviewDrawerContext";

export const useRenderHtmlPreview = () => {
  const { previewDrawerOpen } = usePreviewDrawerContext();
  const { mutate: parseData, isLoading } = useParseData();
  const appStore = useAppStore();
  const { generateBrochureInput } = appStore;
  const [variableGroups, setVariableGroups] = useState<VariableGroups>();
  const [orderInfo, setOrderInfo] = useState<OrderInfo>();
  const [error, setError] = useState<string>();

  const triggerRender = useCallback(() => {
    const dataStr = generateBrochureInput?.replace(/(<([^>]+)>)/gi, "");
    if (!dataStr) {
      setVariableGroups(undefined);
      return;
    }
    parseData(JSON.parse(dataStr), {
      onSuccess: data => {
        setError(undefined);
        const varGroups = {
          table: data.variables.filter((variable: Variable) => variable.type === "table"),
          section: data.variables.filter((variable: Variable) => variable.type === "section"),
          text: data.variables.filter((variable: Variable) => variable.type === "text"),
        };
        setVariableGroups(varGroups);
        const { productKey: product, planId, preparedFor, state } = data.proposalMetadata;
        setOrderInfo({ product, planId, preparedFor, state });
      },
      onError: (err: any) => {
        setError(err.error);
        setVariableGroups(undefined);
        setOrderInfo(undefined);
      },
    });
  }, [generateBrochureInput, parseData]);

  useEffect(() => {
    if (!previewDrawerOpen || !generateBrochureInput) return;
    triggerRender();
  }, [previewDrawerOpen, generateBrochureInput, triggerRender]);

  return { variableGroups, orderInfo, error, isLoading };
};
