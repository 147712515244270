import {
  ApiConfig,
  BackupVersion,
  CopyProductResponse,
  CountResponse,
  Environment,
  JobStatusRequestKeys,
  MessageResponse,
  PaginationQueryParams,
} from "../types/api";
import {
  Benefit,
  ConditionalMapping,
  General,
  LimitExclusion,
  Log,
  Product,
  State,
} from "../types/brochure";
import { ParseDataOutput } from "../types/lambda";
import HttpClient from "./httpClient";

const localUrl = "http://localhost:3111";

const configGenerator = (env: Environment): ApiConfig => {
  const deployedUrl = `https://${process.env.REACT_APP_API_DOMAIN}/${env}`;
  console.log("deployedUrl", deployedUrl);
  console.log(process.env.REACT_APP_API_DOMAIN);
  const url = `${env === "local" ? localUrl : deployedUrl}/brochure`;
  return {
    services: {
      generals: `${url}/generals`,
      benefits: `${url}/benefits`,
      limitExclusions: `${url}/limit-exclusions`,
      products: `${url}/products`,
      states: `${url}/states`,
      triggerBrochure: `${url}/trigger`,
      tools: `${url}/tools`,
      logs: `${url}/logs`,
      conditionalMappings: `${url}/conditional-mappings`,
    },
  };
};

class APIClass {
  private _config?: ApiConfig;

  public get services() {
    if (!this._config) {
      this._config = configGenerator(process.env.REACT_APP_ENV as Environment);
    }
    return this.getAPIServices(this._config);
  }

  private getAPIServices(config: ApiConfig) {
    return {
      postGeneral: (body: General) => {
        const url = config.services.generals;
        return HttpClient.post<General>(url, body);
      },
      postGeneralBatch: (body: General, states: State[]) => {
        const url = `${config.services.generals}/batch`;
        return HttpClient.post<General>(url, { body, states });
      },
      getListGenerals: (state: State, product: Product) => {
        const url = `${config.services.generals}?state_id=${state.id}&product_id=${product.id}`;
        return HttpClient.get<General[]>(url);
      },
      putGeneral: (body: General) => {
        const url = `${config.services.generals}/${body.id}`;
        return HttpClient.put<General>(url, body);
      },
      deleteGeneral: (id: number) => {
        const url = `${config.services.generals}/${id}`;
        return HttpClient.delete<General>(url);
      },
      duplicateGenerals: (fromState: State, toStates: State[], product: Product) => {
        const url = `${config.services.generals}/duplicate`;
        return HttpClient.post<General>(url, { fromState, toStates, product });
      },
      postBenefit: (body: Benefit) => {
        const url = config.services.benefits;
        return HttpClient.post<Benefit>(url, body);
      },
      postBenefitBatch: (body: Benefit, states: State[]) => {
        const url = `${config.services.benefits}/batch`;
        return HttpClient.post<Benefit>(url, { body, states });
      },
      getListBenefits: (state: State, product: Product) => {
        const url = `${config.services.benefits}?state_id=${state.id}&product_id=${product.id}`;
        return HttpClient.get<Benefit[]>(url);
      },
      putBenefit: (body: Benefit) => {
        const url = `${config.services.benefits}/${body.id}`;
        return HttpClient.put<Benefit>(url, body);
      },
      deleteBenefit: (id: number) => {
        const url = `${config.services.benefits}/${id}`;
        return HttpClient.delete<Benefit>(url);
      },
      reorderBenefit: (body: Benefit, oldIndex: number, targetIndex: number) => {
        const url = `${config.services.benefits}/reorder`;
        return HttpClient.post<Benefit>(url, { body, oldIndex, targetIndex });
      },
      duplicateBenefits: (fromState: State, toStates: State[], product: Product) => {
        const url = `${config.services.benefits}/duplicate`;
        return HttpClient.post<Benefit>(url, { fromState, toStates, product });
      },
      postLimitExclusion: (body: LimitExclusion) => {
        const url = config.services.limitExclusions;
        return HttpClient.post<LimitExclusion>(url, body);
      },
      postLimitExclusionBatch: (body: LimitExclusion, states: State[]) => {
        const url = `${config.services.limitExclusions}/batch`;
        return HttpClient.post<LimitExclusion>(url, { body, states });
      },
      getListLimitExclusions: (state: State, product: Product) => {
        const url = `${config.services.limitExclusions}?state_id=${state.id}&product_id=${product.id}`;
        return HttpClient.get<LimitExclusion[]>(url);
      },
      putLimitExclusion: (body: LimitExclusion) => {
        const url = `${config.services.limitExclusions}/${body.id}`;
        return HttpClient.put<LimitExclusion>(url, body);
      },
      deleteLimitExclusion: (id: number) => {
        const url = `${config.services.limitExclusions}/${id}`;
        return HttpClient.delete<LimitExclusion>(url);
      },
      reorderLimitExclusion: (body: LimitExclusion, oldIndex: number, targetIndex: number) => {
        const url = `${config.services.limitExclusions}/reorder`;
        return HttpClient.post<LimitExclusion>(url, { body, oldIndex, targetIndex });
      },
      duplicateLimitExclusions: (fromState: State, toStates: State[], product: Product) => {
        const url = `${config.services.limitExclusions}/duplicate`;
        return HttpClient.post<LimitExclusion>(url, { fromState, toStates, product });
      },
      postProduct: (body: Product) => {
        const url = config.services.products;
        return HttpClient.post<Product>(url, body);
      },
      getListProducts: () => {
        return HttpClient.get<Product[]>(config.services.products);
      },
      putProduct: (body: Product) => {
        const url = `${config.services.products}/${body.id}`;
        return HttpClient.put<Product>(url, body);
      },
      deleteProduct: (id: number) => {
        const url = `${config.services.products}/${id}`;
        return HttpClient.delete<Product>(url);
      },
      postTriggerBrochure: (body: string, userEmail?: string) => {
        const url = userEmail
          ? `${config.services.triggerBrochure}?user=${userEmail}`
          : config.services.triggerBrochure;
        return HttpClient.post<string>(url, body);
      },
      postCopyProducts: (toEnv: string, products: string[], states: string[]) => {
        const url = `${config.services.tools}/copy-products`;
        const body = { fromEnv: process.env.ENV, toEnv, products, states };
        return HttpClient.post<CopyProductResponse>(url, body);
      },
      getCopyProductsStatus: (requestKeys?: JobStatusRequestKeys) => {
        const keys = btoa(JSON.stringify(requestKeys));
        const url = `${config.services.tools}/copy-products-status?requestKeys=${keys}`;
        return HttpClient.get<string>(url);
      },
      getCreateBackup: () => {
        const url = `${config.services.tools}/create-backup`;
        return HttpClient.get<MessageResponse>(url);
      },
      postRestoreBackup: (versionId: string) => {
        const url = `${config.services.tools}/restore-backup`;
        return HttpClient.post<MessageResponse>(url, { versionId });
      },
      getListBackupVersions: () => {
        const url = `${config.services.tools}/list-backup-versions`;
        return HttpClient.get<BackupVersion[]>(url);
      },
      postParseData: (body: string) => {
        const url = `${config.services.tools}/parse-data`;
        return HttpClient.post<ParseDataOutput>(url, body);
      },
      getListLogs: ({ limit, offset }: PaginationQueryParams) => {
        const url = `${config.services.logs}?limit=${limit}&offset=${offset}`;
        return HttpClient.get<Log[]>(url);
      },
      getLogsCount: () => {
        const url = `${config.services.logs}/count`;
        return HttpClient.get<CountResponse>(url);
      },
      getConditionalMappings: () => {
        const url = `${config.services.conditionalMappings}`;
        return HttpClient.get<ConditionalMapping[]>(url);
      },
      postConditionalMapping: (body: ConditionalMapping) => {
        const url = `${config.services.conditionalMappings}`;
        return HttpClient.post<ConditionalMapping>(url, body);
      },
      putConditionalMapping: (body: ConditionalMapping) => {
        const url = `${config.services.conditionalMappings}/${body.id}`;
        return HttpClient.put<ConditionalMapping>(url, body);
      },
      deleteConditionalMapping: (id: number) => {
        const url = `${config.services.conditionalMappings}/${id}`;
        return HttpClient.delete<ConditionalMapping>(url);
      },
    };
  }
}

const API = new APIClass();

export default API;
