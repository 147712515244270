import { Autocomplete, Button, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { RulesType } from "../../../types/app";
import { Product } from "../../../types/brochure";

export type NameType = {
  name?: string;
};

export type Props<T extends NameType> = {
  options?: T[];
  fieldName: string;
  rules?: RulesType;
};

export const ControlledAutocomplete = <T extends NameType>({
  options,
  fieldName,
  rules,
}: Props<T>) => {
  const fieldNameUc = fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();
  return (
    <Controller
      control={control}
      rules={rules}
      render={({ field: { onChange, value } }) => {
        const val = value
          ?.map((name: string | undefined) => options?.find(product => product.name === name))
          .filter(Boolean) as T[];
        return (
          <>
            <Autocomplete
              multiple
              limitTags={3}
              id={`${fieldName}-select`}
              value={val}
              options={options ?? []}
              getOptionLabel={(option: Product) => option.name ?? ""}
              isOptionEqualToValue={(option: Product, value: Product) => option.id === value.id}
              renderInput={params => (
                <TextField
                  {...params}
                  label={fieldNameUc}
                  placeholder={fieldNameUc}
                  error={!!errors[fieldName]}
                />
              )}
              onChange={(_, values) => onChange(values.map(value => value.name ?? ""))}
              fullWidth
            />
            <Button
              onClick={() => {
                setValue(
                  fieldName,
                  options?.map(value => value.name ?? ""),
                  { shouldValidate: true },
                );
              }}
              variant="text"
              size="small"
            >
              Select All
            </Button>
          </>
        );
      }}
      name={fieldName}
    />
  );
};
