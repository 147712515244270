import { LegacyRef } from "react";
import { Row, Variable, VariableGroups } from "../../types/lambda";
import { adaptTextToHtml, cleanTags } from "../../utils/html.utils";
import { isSubHeaderRow } from "../../utils/validation.utils";
import styles from "./RenderPreview.module.scss";

const renderContent = (variable: Variable): JSX.Element | null => {
  switch (variable.type) {
    case "text":
      return <p>{cleanTags(variable.data.content)}</p>;
    case "table":
      return (
        <div className={styles.tableWrapper}>
          <table className={styles.renderPreview}>
            <thead>
              <tr>
                <th>{cleanTags(variable.data.header.title)}</th>
                {variable.data.header.value && (
                  <th className={styles.headerValue}>{cleanTags(variable.data.header.value)}</th>
                )}
              </tr>
            </thead>
            <tbody>
              {variable.data.rows?.map((row: Row, index: number) => (
                <tr key={index}>
                  <td
                    colSpan={isSubHeaderRow(row) || !row.value ? 2 : 1}
                    style={{ width: isSubHeaderRow(row) || !row.value ? "100%" : "80%" }}
                  >
                    {!isSubHeaderRow(row) && row.title && <strong>{cleanTags(row.title)}</strong>}
                    {row.subtitle && <span> {cleanTags(row.subtitle)}</span>}
                    {!isSubHeaderRow(row) && row.description && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: adaptTextToHtml(cleanTags(row.description)),
                        }}
                      />
                    )}
                    {isSubHeaderRow(row) && row.subHeader && (
                      <div>
                        <strong>{cleanTags(row.subHeader)}</strong>
                      </div>
                    )}
                  </td>
                  {!isSubHeaderRow(row) && row.value && (
                    <td style={{ width: "20%" }} dangerouslySetInnerHTML={{ __html: row.value }} />
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    case "section":
      return (
        <section>
          <h2>{cleanTags(variable.data.title ?? "")}</h2>
          <p
            dangerouslySetInnerHTML={{ __html: adaptTextToHtml(cleanTags(variable.data.content)) }}
          />
        </section>
      );
    default:
      return null;
  }
};

export const HtmlRenderer = ({
  variableGroups,
  targetRef,
}: {
  variableGroups?: VariableGroups;
  targetRef?: LegacyRef<HTMLDivElement>;
}) => {
  return (
    <div className={styles.renderContainer} ref={targetRef}>
      {Object.entries(variableGroups ?? {}).map(([type, vars]) => {
        const renderedVars = vars.map((variable: Variable, index: number) => (
          <div key={index}>
            {type === "text" && <h3>{variable.name}</h3>}
            {renderContent(variable)}
          </div>
        ));
        if (type === "section") {
          return <div className={styles.sections}>{renderedVars}</div>;
        }
        return renderedVars;
      })}
    </div>
  );
};
