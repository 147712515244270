import { useQuery } from "@tanstack/react-query";
import { Log } from "../types/brochure";
import API from "../services/api";
import { CountResponse, PaginationQueryParams } from "../types/api";

export const useLogs = ({ limit, offset }: PaginationQueryParams) =>
  useQuery<Log[], Error>(["logs", limit, offset], () =>
    API.services.getListLogs({ limit, offset }),
  );

export const useLogCount = () =>
  useQuery<CountResponse, Error>(["logs", "count"], API.services.getLogsCount);
