import { Grid, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { useMgmtDrawer } from "../../../context/MgmtDrawerContext";

export const AddAnotherCheckbox = () => {
  const { addAnother, updateAddAnother } = useMgmtDrawer();

  return (
    <Grid item xs={12}>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={addAnother} onChange={() => updateAddAnother(!addAnother)} />}
          label="Save and add another"
        />
      </FormGroup>
    </Grid>
  );
};
