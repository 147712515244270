import { useMutation } from "@tanstack/react-query";
import API from "../services/api";
import { useAuth0 } from "@auth0/auth0-react";

export const useGenerateBrochure = () => {
  const { user } = useAuth0();
  return useMutation<string, Error, string>((body: string) =>
    API.services.postTriggerBrochure(body, user?.email),
  );
};
