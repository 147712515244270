import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useRestoreBackupVersion } from "../../hooks/useTools";
import { useAppStore } from "../../session/store";
import { Log } from "../../types/brochure";

type Props = {
  open: boolean;
  log?: Log;
  toggleDialog: () => void;
};

export const RestoreBackupDialog = ({ open, log, toggleDialog }: Props) => {
  const showAlert = useAppStore(state => state.showAlert);
  const { mutate: restoreBackupVersion, isLoading } = useRestoreBackupVersion();

  const onClose = () => {
    toggleDialog();
  };
  const handleSave = () => {
    if (!log?.data?.versionId) {
      showAlert("No versionId found for Restore log", "error");
      return;
    }
    restoreBackupVersion(log.data?.versionId, {
      onSuccess: () => {
        toggleDialog();
        showAlert("Backup restored successfully", "success");
      },
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">Restore Backup</DialogTitle>
      <DialogContent>
        <Alert severity="warning">
          All data will be reset and restored to {new Date(log?.created_at ?? "").toLocaleString()}
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} disabled={isLoading} autoFocus>
          Restore
        </Button>
      </DialogActions>
    </Dialog>
  );
};
