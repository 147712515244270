import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Controller, FormProvider, useForm, useFormContext } from "react-hook-form";
import { states } from "../../constants";
import { useToolsContext } from "../../context/ToolsContext";
import { useListProducts } from "../../hooks/useProducts";
import { useCopyProduct } from "../../hooks/useTools";
import { useAppStore } from "../../session/store";
import { CopyProductRequest } from "../../types/api";
import { Product, State } from "../../types/brochure";
import { ControlledAutocomplete } from "../forms/fields/ControlledAutocomplete";

const CopyProductSelectionContent = () => {
  const { control } = useFormContext<CopyProductRequest>();
  const { data: allProducts } = useListProducts();

  return (
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Alert severity="info">
            Copy products from the current environment to another environment.
            <br />
            <br />
            If the product already exists on the destination environment, all records will be
            removed and replaced with the current environment's data.
          </Alert>
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            rules={{ required: "Please select an environment" }}
            render={({ field: { onChange, value }, fieldState: { invalid } }) => (
              <FormControl>
                <FormLabel id="env-buttons-group-label" error={invalid}>
                  Copy to:
                </FormLabel>
                <RadioGroup
                  aria-labelledby="env-buttons-group-label"
                  name="env-buttons-group"
                  value={value}
                  onChange={(_, env) => {
                    onChange(env);
                  }}
                >
                  <FormControlLabel value="dev" control={<Radio />} label="Dev" />
                  <FormControlLabel value="stg" control={<Radio />} label="Stage" />
                  <FormControlLabel value="prd" control={<Radio />} label="Production" />
                </RadioGroup>
              </FormControl>
            )}
            name="toEnv"
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledAutocomplete<Product>
            options={allProducts}
            fieldName="products"
            rules={{ validate: value => value.length > 0 || "Please select a product" }}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledAutocomplete<State>
            options={states}
            fieldName="states"
            rules={{ validate: value => value.length > 0 || "Please select a state" }}
          />
        </Grid>
      </Grid>
    </DialogContent>
  );
};

export const CopyProductsDialog = () => {
  const {
    dialogOpen: resetDialogOpen,
    toggleDialog: toggleResetDialog,
    setJobStatusRequestKeys,
  } = useToolsContext();
  const { mutate: copyProducts } = useCopyProduct();
  const methods = useForm<CopyProductRequest>({
    defaultValues: { toEnv: "dev", products: [], states: [] },
    shouldUnregister: false,
  });
  const showAlert = useAppStore(state => state.showAlert);

  const onClose = () => {
    toggleResetDialog();
  };

  const handleSave = () => {
    const { toEnv, products, states } = methods.getValues();
    copyProducts(
      { toEnv, products, states },
      {
        onSuccess: data => {
          methods.reset();
          toggleResetDialog();
          showAlert(`Copying products to ${toEnv.toUpperCase()}`, "info", null, undefined, true);
          setJobStatusRequestKeys({ pk: data.id, sk: data.createdAt });
        },
        onError: (error: any) => {
          showAlert(error.message, "error");
        },
      },
    );
  };

  return (
    <Dialog
      open={resetDialogOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <FormProvider {...methods}>
        <DialogTitle id="alert-dialog-title">Copy Products To Environment</DialogTitle>
        <CopyProductSelectionContent />
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
          <Button onClick={methods.handleSubmit(handleSave)} autoFocus>
            Copy
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};
