import { Grid } from "@mui/material";
import { Control, useWatch } from "react-hook-form";
import { LimitExclusion } from "../../../types/brochure";
import { ControlledTextField } from "../fields/ControlledTextField";
import { QuillTextField } from "../fields/QuillTextField";

export const LimitExclusionForm = ({ control }: { control: Control<LimitExclusion> }) => {
  const node = useWatch({ control, name: "node" });

  // Return null to prevent rendering the Quill editors prior to getting their values,
  // since we cannot reinitialize their configuration after the first render.
  if (!!control._defaultValues.id && !node) return null;

  const allowFormatting = node !== "Disclaimer Header";
  return (
    <>
      <Grid item xs={12}>
        <ControlledTextField field="input" control={control} />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField field="node" control={control} />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField field="name" control={control} />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField field="months" control={control} />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField field="months_reference" control={control} />
      </Grid>
      <Grid item xs={12}>
        {allowFormatting ? (
          <QuillTextField label="Text" field="text" control={control} />
        ) : (
          <ControlledTextField field="text" control={control} />
        )}
      </Grid>
    </>
  );
};
