import { GridColDef } from "@mui/x-data-grid";
import { GridActionsCellItem, GridRowParams } from "@mui/x-data-grid-pro";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Benefit } from "../../types/brochure";
import styles from "./QuillColumn.module.scss";

export const getColumns = (
  handleEditClick: (row: GridRowParams<Benefit>) => void,
  handleDeleteClick: (row: GridRowParams<Benefit>) => void,
  handleDupRowClick: (row: GridRowParams<Benefit>) => void,
): GridColDef[] => {
  return [
    { field: "input", headerName: "Input", flex: 1, minWidth: 80, sortable: false },
    { field: "node", headerName: "Node", flex: 1, minWidth: 80, sortable: false },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      minWidth: 80,
      sortable: false,
      cellClassName: styles.quillCell,
      renderCell: params => (
        <div
          dangerouslySetInnerHTML={{
            __html: params.value,
          }}
        />
      ),
    },
    {
      field: "subtitle",
      headerName: "Subtitle",
      flex: 1,
      minWidth: 80,
      sortable: false,
      cellClassName: styles.quillCell,
      renderCell: params => (
        <div
          dangerouslySetInnerHTML={{
            __html: params.value,
          }}
        />
      ),
    },
    {
      field: "value",
      headerName: "Value",
      flex: 1,
      minWidth: 80,
      sortable: false,
      cellClassName: styles.quillCell,
      renderCell: params => (
        <div
          dangerouslySetInnerHTML={{
            __html: params.value,
          }}
        />
      ),
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      minWidth: 80,
      sortable: false,
      cellClassName: styles.quillCell,
      renderCell: params => (
        <div
          dangerouslySetInnerHTML={{
            __html: params.value,
          }}
        />
      ),
    },
    { field: "days", headerName: "Days", flex: 1, minWidth: 80, sortable: false },
    { field: "days_reference", headerName: "Days Ref", flex: 1, minWidth: 80, sortable: false },
    { field: "months", headerName: "Months", flex: 1, minWidth: 80, sortable: false },
    { field: "months_reference", headerName: "Months Ref", flex: 1, minWidth: 80, sortable: false },
    { field: "years", headerName: "Years", flex: 1, minWidth: 80, sortable: false },
    { field: "years_reference", headerName: "Years Ref", flex: 1, minWidth: 80, sortable: false },
    { field: "amount", headerName: "Amount", flex: 1, minWidth: 80, sortable: false },
    { field: "amount_reference", headerName: "Amount Ref", flex: 1, minWidth: 80, sortable: false },
    { field: "start_time", headerName: "Start Time", flex: 1, minWidth: 80, sortable: false },
    {
      field: "start_time_reference",
      headerName: "Start Time Ref",
      flex: 1,
      minWidth: 80,
      sortable: false,
    },
    { field: "end_time", headerName: "End Time", flex: 1, minWidth: 80, sortable: false },
    {
      field: "end_time_reference",
      headerName: "End Time Ref",
      flex: 1,
      minWidth: 80,
      sortable: false,
    },
    {
      field: "actions",
      type: "actions",
      getActions: params => [
        <GridActionsCellItem
          key={`${params.id}-edit`}
          icon={<EditIcon />}
          label="Edit"
          onClick={() => handleEditClick(params)}
        />,
        <GridActionsCellItem
          key={`${params.id}-dup`}
          icon={<ContentCopyIcon />}
          label="Duplicate"
          onClick={() => handleDupRowClick(params)}
        />,
        <GridActionsCellItem
          key={`${params.id}-delete`}
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => handleDeleteClick(params)}
        />,
      ],
    },
  ];
};
