import { Autocomplete, Button, Chip, TextField } from "@mui/material";
import { states } from "../../../constants";
import { useMgmtDrawer } from "../../../context/MgmtDrawerContext";
import { useAppStore } from "../../../session/store";
import { useMemo } from "react";

export const DuplicateStateSelect = () => {
  const { updateDupStates, dupStates } = useMgmtDrawer();
  const currentState = useAppStore(state => state.state);

  const otherStates = useMemo(() => states.filter(v => v.id !== currentState?.id), [currentState]);

  return (
    <>
      <Autocomplete
        multiple
        limitTags={3}
        id="multiple-limit-tags"
        options={otherStates}
        getOptionLabel={option => option.name ?? ""}
        value={dupStates}
        onChange={(_, value) => {
          updateDupStates(value);
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={params => <TextField {...params} label="States" placeholder="States" />}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={option.name}
              {...getTagProps({ index })}
              disabled={currentState === option}
            />
          ))
        }
        fullWidth
      />
      <Button onClick={() => updateDupStates(otherStates)} variant="text" size="small">
        Select All
      </Button>
    </>
  );
};
