import { Grid, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useCallback } from "react";
import { Control, Controller, useFieldArray, useWatch } from "react-hook-form";
import { useListConditionalMappings } from "../../../hooks/useConditionalMappings";
import { RowConditionalMapping } from "../../../types/brochure";
import { wordsToSnakeCase } from "../../../utils/forms.utils";
import { ConditionalMappingAccordion } from "./ConditionalMappingAccordion";

export type TypeWithConditionalMappings = {
  conditional_mappings?: RowConditionalMapping[];
};

export const ConditionalMappingOptions = ({
  control,
}: {
  control: Control<TypeWithConditionalMappings>;
}) => {
  const { data: mappings } = useListConditionalMappings();
  const { fields, prepend, remove } = useFieldArray({
    control,
    name: "conditional_mappings",
    keyName: "uuid",
  });
  const conditionalMappingFormVals = useWatch({ control, name: "conditional_mappings" });

  const onMappingSelectChange = useCallback(
    (e: SelectChangeEvent) => {
      const selectedMapping = e.target.value;
      const mapping = mappings?.find(mapping => mapping.name === selectedMapping);
      if (!mapping?.id) return;
      prepend({
        input: "",
        values: [],
        reference_var: `[cm_${wordsToSnakeCase(mapping.name ?? "")}]`,
        conditional_id: mapping.id,
      });
    },
    [prepend, mappings],
  );

  return (
    <Grid item xs={12}>
      <Controller
        name={"conditional_mappings"}
        control={control}
        render={_ => (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Select
                  fullWidth={true}
                  size="small"
                  value={"select"}
                  onChange={onMappingSelectChange}
                >
                  <MenuItem disabled value="select">
                    <em>Add Conditional Mapping</em>
                  </MenuItem>
                  {mappings?.map(option => (
                    <MenuItem key={option.name} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            {fields?.length > 0 && (
              <>
                <h5>Applied Conditional Mappings</h5>
                <Grid container spacing={2}>
                  {fields?.map(({ conditional_id, uuid }, index) => {
                    const mapping = mappings?.find(m => m.id === conditional_id);
                    return (
                      <Grid key={uuid} item xs={12}>
                        <ConditionalMappingAccordion
                          key={uuid}
                          control={control}
                          index={index}
                          mapping={mapping}
                          conditionalMappingFormVals={conditionalMappingFormVals}
                          remove={() => remove(index)}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </>
            )}
          </>
        )}
      />
      <br />
    </Grid>
  );
};
