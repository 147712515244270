import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import API from "../services/api";
import { Product } from "../types/brochure";

const QUERY_KEY_LIST_PRODUCTS = "listProducts";

export const useListProducts = () => {
  return useQuery<Product[], Error>([QUERY_KEY_LIST_PRODUCTS], () =>
    API.services.getListProducts(),
  );
};

export const useAddProduct = () => {
  const queryClient = useQueryClient();
  return useMutation<Product, Error, Product>((body: Product) => API.services.postProduct(body), {
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_LIST_PRODUCTS],
      });
    },
  });
};

export const useUpdateProduct = () => {
  const queryClient = useQueryClient();
  return useMutation<Product, Error, Product>((body: Product) => API.services.putProduct(body), {
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_LIST_PRODUCTS],
      });
    },
  });
};

export const useDeleteProduct = () => {
  const queryClient = useQueryClient();
  return useMutation<Product, Error, Product>(
    (body: Product) => API.services.deleteProduct(Number(body.id)),
    {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_LIST_PRODUCTS],
        });
      },
    },
  );
};
