import { GridColDef } from "@mui/x-data-grid";
import { GridActionsCellItem, GridRowParams } from "@mui/x-data-grid-pro";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { LimitExclusion } from "../../types/brochure";

export const getColumns = (
  handleEditClick: (row: GridRowParams<LimitExclusion>) => void,
  handleDeleteClick: (row: GridRowParams<LimitExclusion>) => void,
): GridColDef[] => {
  return [
    { field: "name", headerName: "Name", flex: 1, minWidth: 80, sortable: false },
    {
      field: "actions",
      type: "actions",
      getActions: params => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          onClick={() => handleEditClick(params)}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => handleDeleteClick(params)}
        />,
      ],
    },
  ];
};
