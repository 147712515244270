export const dev = {
  name: "BrochuresDev",
  cid: "brochures-dev",
  xcid: "",
  region: "us-east-1",
  auth: {
    audience: "https://auth-dev-api.constech.io/",
    domain: "auth-dev.constech.io",
    clientId: "G0HQadPaxpKbnv8FqSzgyIlDm1L7Vylz",
    organization: "org_4pCNZclJgAizkJsr",
  },
};

export const prd = {
  name: "BrochuresPrd",
  cid: "brochures",
  xcid: "",
  region: "us-east-1",
  auth: {
    audience: "https://auth-api.constech.io/",
    domain: "auth.constech.io",
    clientId: "YO091GQPW9yeLYU0vJefW1FBnt7iO9kl",
    organization: "org_vzbiuVO2pVRwJ7Bp",
  },
};

export const clientsArr = [dev, prd];
