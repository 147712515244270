import {
  GridRowParams,
  GridColDef,
  GridActionsCellItem,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { Log } from "../../types/brochure";
import RestoreIcon from "@mui/icons-material/Restore";
import styles from "./LogColumns.module.scss";

const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

const renderLogData = (params: GridRenderCellParams<Log>) => {
  const data = params.value;
  const { description, ...rest } = data;
  let dataObj: Log = { description, ...rest };
  return (
    <table className={styles.logTable}>
      <tbody>
        {Object.entries(dataObj).map(([key, value]) => {
          if (value === null || value === undefined || value === "") return null;
          return (
            <tr key={key}>
              <td className={styles.key}>
                <strong>{capitalize(key)}</strong>
              </td>
              <td>
                {Array.isArray(value) ? (
                  value.map((item, index) => (
                    <span key={index}>
                      {JSON.stringify(item)}
                      {index !== value.length - 1 ? ", " : ""}
                    </span>
                  ))
                ) : typeof value === "object" ? (
                  <table>
                    <tbody>
                      {Object.entries(value ?? {}).map(([innerKey, innerValue]) => (
                        <tr key={innerKey}>
                          <td className={styles.innerKey}>{innerKey}</td>
                          <td>{`${innerValue}`}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <span>{value}</span>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export const getColumns = (handleRestoreClick: (row: GridRowParams<Log>) => void): GridColDef[] => {
  return [
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 30,
      renderCell: params => capitalize(params.value),
    },
    {
      field: "created_at",
      headerName: "Created At",
      flex: 1,
      minWidth: 80,
      renderCell: params => new Date(params.value).toLocaleString(),
    },
    {
      field: "data",
      headerName: "Data",
      flex: 1,
      minWidth: 80,
      renderCell: renderLogData,
      cellClassName: styles.logDataCell,
    },
    { field: "email", headerName: "User", flex: 1, minWidth: 80 },
    {
      field: "actions",
      type: "actions",
      getActions: params =>
        params.row["action"] === "backup"
          ? [
              <GridActionsCellItem
                icon={<RestoreIcon />}
                label="Edit"
                onClick={() => handleRestoreClick(params)}
              />,
            ]
          : [],
    },
  ];
};
