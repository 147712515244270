import FormLabel from "@mui/material/FormLabel";
import { Control, Controller } from "react-hook-form";
import ReactQuill from "react-quill";
import "quill/dist/quill.snow.css";
import Quill from "quill";
const Clipboard = Quill.import("modules/clipboard");
const Delta = Quill.import("delta");

/**
 * This class is a custom clipboard module for Quill that overrides the default pasting behavior
 * which includes styles and formatting. This module only pastes plain text.
 */
class PlainClipboard extends Clipboard {
  onPaste(e: any) {
    e.preventDefault();
    const range = this.quill.getSelection();
    const text = e.clipboardData.getData("text/plain");
    const delta = new Delta().retain(range.index).delete(range.length).insert(text);
    const index = text.length + range.index;
    const length = 0;
    this.quill.updateContents(delta, "silent");
    this.quill.setSelection(index, length, "silent");
    this.quill.scrollIntoView();
  }
}

Quill.register("modules/clipboard", PlainClipboard, true);

export const QuillCodeField = ({
  label,
  field,
  control,
}: {
  label: string;
  field: string;
  control: Control<any>;
}) => {
  const modules = {
    toolbar: false,
  };

  return (
    <>
      <FormLabel>{label}</FormLabel>
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <ReactQuill theme="snow" value={value ?? ""} onChange={onChange} modules={modules} />
        )}
        name={field}
      />
    </>
  );
};
