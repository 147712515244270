import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import API from "../services/api";
import {
  BackupVersion,
  CopyProductRequest,
  CopyProductResponse,
  JobStatus,
  JobStatusRequestKeys,
  MessageResponse,
} from "../types/api";
import { ParseDataOutput } from "../types/lambda";

const QUERY_KEY_LIST_BACKUP_VERSIONS = "listBackupVersions";

export const useCopyProduct = () => {
  return useMutation<CopyProductResponse, Error, CopyProductRequest>(
    ({ toEnv, products, states }) => API.services.postCopyProducts(toEnv, products, states),
  );
};

export const useListBackupVersions = () => {
  return useQuery<BackupVersion[], Error>([QUERY_KEY_LIST_BACKUP_VERSIONS], () =>
    API.services.getListBackupVersions(),
  );
};

export const useRestoreBackupVersion = () => {
  const queryClient = useQueryClient();
  return useMutation<MessageResponse, Error, string>(
    (versionId: string) => API.services.postRestoreBackup(versionId),
    {
      onSuccess: () => {
        queryClient.getQueryCache().clear();
      },
    },
  );
};

export const useCreateBackup = () => {
  return useMutation<MessageResponse, Error>(() => API.services.getCreateBackup());
};

export const useCopyProductStatus = ({
  requestKeys,
  onSuccess,
  onError,
}: {
  requestKeys?: JobStatusRequestKeys;
  onSuccess?: (data: JobStatus) => void;
  onError?: () => void;
}) => {
  return useQuery<string, null, JobStatus>({
    queryKey: ["copy-products", requestKeys],
    queryFn: () => API.services.getCopyProductsStatus(requestKeys),
    refetchInterval: job => {
      return !job || !["completed", "failed"].includes(job.status) ? 3000 : false;
    },
    staleTime: Infinity,
    enabled: !!requestKeys,
    onSuccess: data => {
      onSuccess?.(data);
    },
    onError: () => {
      onError?.();
    },
  });
};

export const useParseData = () => {
  return useMutation<ParseDataOutput, Error, string>((body: string) =>
    API.services.postParseData(body),
  );
};
