import { Grid } from "@mui/material";
import { Control } from "react-hook-form";
import { General } from "../../../types/brochure";
import { ControlledTextField } from "../fields/ControlledTextField";

export const GeneralForm = ({ control }: { control: Control<General> }) => {
  return (
    <>
      <Grid item xs={12}>
        <ControlledTextField field="input" control={control} />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField field="name" control={control} />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField field="text" control={control} />
      </Grid>
    </>
  );
};
