import { Autocomplete, Button, Chip, TextField } from "@mui/material";
import { useMemo } from "react";
import { states } from "../../../constants";
import { useAppStore } from "../../../session/store";
import { useMgmtDrawer } from "../../../context/MgmtDrawerContext";

export const EditStateSelect = () => {
  const { updateSaveStates, saveStates } = useMgmtDrawer();
  const currentState = useAppStore(state => state.state);

  const checkedVals = useMemo(() => {
    const updatedStates = saveStates.filter(v => v.id !== currentState?.id);
    return [...updatedStates, ...(currentState ? [currentState] : [])].sort((a, b) => a.id - b.id);
  }, [saveStates, currentState]);

  return (
    <>
      <Autocomplete
        multiple
        limitTags={2}
        id="multiple-limit-tags"
        options={states}
        getOptionLabel={option => option.name ?? ""}
        value={checkedVals}
        onChange={(_, value) => {
          updateSaveStates(value.filter(v => v.id !== currentState?.id));
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={params => <TextField {...params} label="States" placeholder="States" />}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={option.name}
              {...getTagProps({ index })}
              disabled={currentState === option}
            />
          ))
        }
        fullWidth
      />
      <Button
        onClick={() => updateSaveStates(states.filter(v => v.id !== currentState?.id))}
        variant="text"
        size="small"
      >
        Select All
      </Button>
    </>
  );
};
