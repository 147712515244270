import { BatchRequest } from "../types/api";
import { State } from "../types/brochure";
import { SaveFieldsMode } from "../types/drawer";

export const bodyForBatchRequest = <T extends object>(
  body: T,
  states: State[],
  saveFieldsMode: SaveFieldsMode,
  dirtyFields: { [key: string]: boolean | Record<string, unknown>[] },
): BatchRequest<T> => {
  if (saveFieldsMode === "all") {
    return { body, states };
  }
  const dirtyFieldsKeys = Object.keys(dirtyFields);
  const dirtyBody = dirtyFieldsKeys.reduce((acc, key) => {
    return { ...acc, [key]: body[key as keyof T] };
  }, {});
  return { body: { ...dirtyBody, ...("order" in body ? { order: body.order } : {}) } as T, states };
};
