import { Add as AddIcon } from "@mui/icons-material";
import { Box, Drawer, Fab, Grid } from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid-pro";
import { drawerSx, fabSx } from "../components/constants";
import { getColumns } from "../components/datagrids/BenefitColumns";
import { PersistedDataGrid } from "../components/datagrids/PersistedDataGrid";
import { DeleteDialog } from "../components/dialogs/DeleteDialog";
import { DuplicateDialog } from "../components/dialogs/DuplicateDialog";
import { AddBenefit } from "../components/forms/add/AddBenefit";
import { EditBenefit } from "../components/forms/edit/EditBenefit";
import { Header } from "../components/pages/Header";
import { ProductStateSelect } from "../components/pages/ProductStateSelect";
import { MgmtDrawerProvider, useMgmtDrawer } from "../context/MgmtDrawerContext";
import {
  useDeleteBenefit,
  useDuplicateBenefits,
  useListBenefits,
  useReorderBenefit,
} from "../hooks/useBenefits";
import { useEventHandlers } from "../hooks/useEventHandlers";
import { Benefit } from "../types/brochure";
import { FormProvider, useForm } from "react-hook-form";

export const BenefitsManagement = () => {
  const { isOpen, modMode, selectedRow, toggleMgmtDrawer, toggleDupConfirmDialog } =
    useMgmtDrawer();
  const methods = useForm<Benefit>();
  const { data: rows, isLoading } = useListBenefits();
  const { mutate: deleteBenefit } = useDeleteBenefit();
  const { mutate: reorderBenefit } = useReorderBenefit();
  const { mutate: duplicateBenefits, isLoading: duplicateLoading } = useDuplicateBenefits();
  const {
    handleAddClick,
    handleDeleteClick,
    handleDupRowClick,
    handleEditClick,
    handleRowOrderChange,
  } = useEventHandlers<Benefit>({ reorderFn: reorderBenefit });

  const columns = getColumns(handleEditClick, handleDeleteClick, handleDupRowClick);

  return (
    <FormProvider {...methods}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3} justifyContent="center">
          <Header
            title="Benefits"
            handleDupClick={toggleDupConfirmDialog}
            duplicateEnabled={!!rows?.length}
          />
          <Grid item xs={12} md={12} sx={{ width: "100%" }}>
            <ProductStateSelect />
          </Grid>
          <Grid item xs={12} md={12} sx={{ minHeight: 350, width: "100%" }}>
            <PersistedDataGrid
              name="benefits"
              rows={rows ?? []}
              columns={columns}
              loading={isLoading}
              slots={{ toolbar: GridToolbar }}
              initialState={{ pinnedColumns: { right: ["actions"] } }}
              rowReordering
              onRowOrderChange={handleRowOrderChange}
            />
          </Grid>
        </Grid>
      </Box>
      <Drawer anchor="right" open={isOpen} onClose={toggleMgmtDrawer} sx={drawerSx}>
        {modMode === "add" ? <AddBenefit /> : <EditBenefit selectedRow={selectedRow} />}
      </Drawer>
      <DeleteDialog type="Benefits" updateFn={deleteBenefit} loading={isLoading} />
      <Fab color="primary" aria-label="add" sx={fabSx} onClick={handleAddClick}>
        <AddIcon />
      </Fab>
      <DuplicateDialog type="Benefits" updateFn={duplicateBenefits} loading={duplicateLoading} />
    </FormProvider>
  );
};

export const Benefits = () => {
  return (
    <MgmtDrawerProvider<Benefit>>
      <BenefitsManagement />
    </MgmtDrawerProvider>
  );
};
