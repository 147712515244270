import { Button, Grid } from "@mui/material";
import { GridRowParams } from "@mui/x-data-grid";
import { FormProvider, useForm, useFormState } from "react-hook-form";
import {
  useUpdateLimitExclusion,
  useUpdateLimitExclusionBatch,
} from "../../../hooks/useLimitExclusions";
import { LimitExclusion } from "../../../types/brochure";
import { LimitExclusionForm } from "../base/LimitExclusionForm";
import { objNullsToStrings } from "../../../utils/models.utils";
import { useMgmtDrawer } from "../../../context/MgmtDrawerContext";
import { SaveConfirmDialog } from "../../dialogs/SaveConfirmDialog";
import { EditStateSelect } from "../fields/EditStateSelect";
import { bodyForBatchRequest } from "../../../utils/request.utils";
import { useAppStore } from "../../../session/store";

export const EditLimitExclusion = ({
  selectedRow,
}: {
  selectedRow: GridRowParams<LimitExclusion> | null;
}) => {
  const methods = useForm<LimitExclusion>({
    defaultValues: objNullsToStrings(selectedRow?.row ?? {}),
  });
  const { control, handleSubmit } = methods;
  const { dirtyFields } = useFormState({ control });
  const { toggleMgmtDrawer, checkedStates, toggleSaveConfirmDialog, saveFieldsMode } =
    useMgmtDrawer();
  const { mutate: updateLimitExclusion } = useUpdateLimitExclusion();
  const { mutate: updateLimitExclusionBatch } = useUpdateLimitExclusionBatch();
  const showAlert = useAppStore(state => state.showAlert);

  const onSuccess = () => {
    toggleMgmtDrawer();
    showAlert("Limit / Exclusion updated successfully", "success");
  };
  const onError = () => showAlert("Limit / Exclusion update failed", "error");
  const onSubmit = handleSubmit(data => {
    if (checkedStates.length === 1) return updateLimitExclusion(data, { onSuccess, onError });
    const batchBody = bodyForBatchRequest(data, checkedStates, saveFieldsMode, dirtyFields);
    updateLimitExclusionBatch(batchBody, { onSuccess, onError });
  });

  return (
    <>
      <h3>Edit Limitation / Exclusion Variable</h3>
      <Grid container spacing={2}>
        <FormProvider {...methods}>
          <LimitExclusionForm control={control} />
        </FormProvider>
        <Grid item xs={12}>
          <EditStateSelect />
        </Grid>
        <Grid item xs={12}>
          <div style={{ display: "flex", gap: 10, justifyContent: "space-between" }}>
            <Button
              type="submit"
              variant="contained"
              onClick={() => {
                if (checkedStates.length === 1) return onSubmit();
                toggleSaveConfirmDialog();
              }}
              disabled={!selectedRow}
            >
              Save
            </Button>
          </div>
        </Grid>
      </Grid>
      <SaveConfirmDialog handleSave={onSubmit} control={control} />
    </>
  );
};
