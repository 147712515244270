import { BackupOutlined as BackupIcon, ContentCopy as ContentCopyIcon } from "@mui/icons-material";
import { Button, Divider, Grid, Typography } from "@mui/material";
import { useCreateBackup } from "../../hooks/useTools";
import { useAppStore } from "../../session/store";
import styles from "./Header.module.scss";

type Props = {
  title: string;
  handleDupClick?: () => void;
  duplicateEnabled?: boolean;
};

export const Header = ({ title, duplicateEnabled, handleDupClick }: Props) => {
  const { mutate: createBackup } = useCreateBackup();
  const showAlert = useAppStore(state => state.showAlert);

  return (
    <Grid item xs={12} md={12}>
      <Grid container>
        <Grid item xs={9} md={9}>
          <Typography variant="h5" fontWeight="bold">
            {title}
          </Typography>
        </Grid>
        <Grid item xs={3} md={3} justifyContent="right" className={styles.buttonContainer}>
          {handleDupClick && (
            <Button
              size="small"
              variant="contained"
              startIcon={<ContentCopyIcon />}
              onClick={handleDupClick}
              disabled={!duplicateEnabled}
            >
              Duplicate
            </Button>
          )}
          <Button
            size="small"
            variant="contained"
            startIcon={<BackupIcon />}
            onClick={() => {
              createBackup(undefined, {
                onSuccess: () => showAlert("Backup Created", "success"),
                onError: () => showAlert("Error Creating Backup", "error"),
              });
              showAlert("Creating Backup...", "info");
            }}
          >
            Backup
          </Button>
        </Grid>
      </Grid>
      <Divider variant="fullWidth" sx={{ my: 2 }} />
    </Grid>
  );
};
