import { Button, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { useAddGeneral } from "../../../hooks/useGeneral";
import { General } from "../../../types/brochure";
import { GeneralForm } from "../base/GeneralForm";
import { useAppStore } from "../../../session/store";
import { useMgmtDrawer } from "../../../context/MgmtDrawerContext";
import { AddAnotherCheckbox } from "../fields/AddAnotherCheckbox";
import { useMemo } from "react";
import { objNullsToStrings } from "../../../utils/models.utils";

export const AddGeneral = () => {
  const { toggleMgmtDrawer, addAnother, selectedRow } = useMgmtDrawer();
  const defaultValues = useMemo(() => {
    if (!selectedRow) return {};
    const { id, ...rest } = selectedRow.row;
    return objNullsToStrings(rest);
  }, [selectedRow]);
  const { reset, handleSubmit, control } = useForm<General>({ defaultValues });
  const { mutate: addGeneral } = useAddGeneral();
  const showAlert = useAppStore(state => state.showAlert);

  const onSuccess = () => {
    showAlert("General variable added successfully", "success");
    if (addAnother) return;
    reset({
      input: "",
      name: "",
      text: "",
    });
    toggleMgmtDrawer();
  };
  const onError = () => showAlert("Adding General variable failed", "error");
  const onSubmit = handleSubmit(data => addGeneral(data, { onSuccess, onError }));

  return (
    <>
      <h3>Add General Variable</h3>
      <Grid container spacing={2}>
        <GeneralForm control={control} />
        <AddAnotherCheckbox />
        <Grid item xs={12}>
          <Button type="submit" variant="contained" onClick={onSubmit}>
            Add
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
