import { FormControlLabel, Switch } from "@mui/material";
import { Control, Controller, RegisterOptions, useFormState } from "react-hook-form";

type RulesType = Omit<
  RegisterOptions<any>,
  "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
>;

export const ControlledSwitchField = ({
  control,
  field,
  fieldLabel,
  rules,
  disabled,
}: {
  field: string;
  control: Control<any>;
  fieldLabel?: string;
  helperText?: string;
  rules?: RulesType;
  disabled?: boolean;
}) => {
  const label =
    fieldLabel ??
    field.replace(/_/g, " ").replace(/\w\S*/g, w => w.replace(/^\w/, c => c.toUpperCase()));
  return (
    <Controller
      control={control}
      rules={rules}
      render={({ field: { onChange, onBlur, value } }) => {
        return (
          <FormControlLabel
            control={
              <Switch onBlur={onBlur} onChange={onChange} checked={value} disabled={disabled} />
            }
            label={label}
          />
        );
      }}
      name={field}
    />
  );
};
