import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useMgmtDrawer } from "../../context/MgmtDrawerContext";
import { DuplicateStateSelect } from "../forms/fields/DuplicateStateSelect";
import { useAppStore } from "../../session/store";
import { DuplicateRequest } from "../../types/api";
import { UseMutateFunction } from "@tanstack/react-query";
import { Benefit, General, LimitExclusion } from "../../types/brochure";

type Props = {
  type: "Benefits" | "Limits & Exclusions" | "General";
  updateFn: UseMutateFunction<
    Benefit | LimitExclusion | General | undefined,
    unknown,
    DuplicateRequest,
    unknown
  >;
  loading: boolean;
};

export const DuplicateDialog = ({ type, updateFn, loading }: Props) => {
  const { dupConfirmIsOpen, toggleDupConfirmDialog, dupStates } = useMgmtDrawer();
  const { product, state, showAlert } = useAppStore();

  const onClose = () => {
    toggleDupConfirmDialog();
  };

  const handleSave = () =>
    updateFn(
      { fromState: state, toStates: dupStates, product },
      {
        onSuccess: () => {
          toggleDupConfirmDialog();
          showAlert(`${type} duplicated`, "success");
        },
        onError: () => showAlert(`Failed to duplicate ${type}`, "error"),
      },
    );

  return (
    <Dialog
      open={dupConfirmIsOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">Duplicate {type}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Select the states to which you would like to copy the current {type} data for product{" "}
          <strong>{product?.name}</strong>
        </DialogContentText>
        <br />
        <Alert severity="warning">
          All values currently set for the <strong>{type}</strong> for product{" "}
          <strong>{product?.name}</strong> of the selected states will be overwritten and replaced
          with the values from this page
        </Alert>
        <br />
        <DuplicateStateSelect />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} disabled={dupStates.length === 0 || loading} autoFocus>
          Duplicate
        </Button>
      </DialogActions>
    </Dialog>
  );
};
