import { Add as AddIcon } from "@mui/icons-material";
import { Box, Drawer, Fab, Grid } from "@mui/material";
import { GridRowOrderChangeParams, GridRowParams, GridToolbar } from "@mui/x-data-grid-pro";
import { drawerSx, fabSx } from "../components/constants";
import { getColumns } from "../components/datagrids/LimitExclusionColumns";
import { PersistedDataGrid } from "../components/datagrids/PersistedDataGrid";
import { DeleteDialog } from "../components/dialogs/DeleteDialog";
import { DuplicateDialog } from "../components/dialogs/DuplicateDialog";
import { AddLimitExclusion } from "../components/forms/add/AddLimitExclusion";
import { EditLimitExclusion } from "../components/forms/edit/EditLimitExclusion";
import { Header } from "../components/pages/Header";
import { ProductStateSelect } from "../components/pages/ProductStateSelect";
import { MgmtDrawerProvider, useMgmtDrawer } from "../context/MgmtDrawerContext";
import { useEventHandlers } from "../hooks/useEventHandlers";
import {
  useDeleteLimitExclusion,
  useDuplicateLimitExclusions,
  useListLimitExclusions,
  useReorderLimitExclusion,
} from "../hooks/useLimitExclusions";
import { LimitExclusion } from "../types/brochure";

export const LimitsExclusionsManagement = () => {
  const {
    isOpen,
    modMode,
    selectedRow,
    toggleMgmtDrawer,
    updateSelectedRow,
    toggleDupConfirmDialog,
  } = useMgmtDrawer();
  const { data: rows, isLoading } = useListLimitExclusions();
  const { mutate: deleteLimitExclusion } = useDeleteLimitExclusion();
  const { mutate: reorderLimitExclusion } = useReorderLimitExclusion();
  const { mutate: duplicateLimitExclusions, isLoading: duplicateLoading } =
    useDuplicateLimitExclusions();
  const {
    handleAddClick,
    handleDeleteClick,
    handleDupRowClick,
    handleEditClick,
    handleRowOrderChange,
  } = useEventHandlers<LimitExclusion>({ reorderFn: reorderLimitExclusion });

  const columns = getColumns(handleEditClick, handleDeleteClick, handleDupRowClick);

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3} justifyContent="center">
          <Header
            title="Limits & Exclusions"
            handleDupClick={toggleDupConfirmDialog}
            duplicateEnabled={!!rows?.length}
          />
          <Grid item xs={12} md={12} sx={{ width: "100%" }}>
            <ProductStateSelect />
          </Grid>
          <Grid item xs={12} md={12} sx={{ minHeight: 350, width: "100%" }}>
            <PersistedDataGrid
              name="limitsExclusions"
              rows={rows ?? []}
              columns={columns}
              loading={isLoading}
              onRowClick={(params: GridRowParams<LimitExclusion>) => updateSelectedRow(params)}
              slots={{ toolbar: GridToolbar }}
              rowReordering
              onRowOrderChange={handleRowOrderChange}
            />
          </Grid>
        </Grid>
      </Box>
      <Drawer anchor="right" open={isOpen} onClose={toggleMgmtDrawer} sx={drawerSx}>
        {modMode === "add" ? (
          <AddLimitExclusion />
        ) : (
          <EditLimitExclusion selectedRow={selectedRow} />
        )}
      </Drawer>
      <DeleteDialog
        type="Limits & Exclusions"
        updateFn={deleteLimitExclusion}
        loading={isLoading}
      />
      <Fab color="primary" aria-label="add" sx={fabSx} onClick={handleAddClick}>
        <AddIcon />
      </Fab>
      <DuplicateDialog
        type="Limits & Exclusions"
        updateFn={duplicateLimitExclusions}
        loading={duplicateLoading}
      />
    </div>
  );
};

export const LimitsExclusions = () => {
  return (
    <MgmtDrawerProvider<LimitExclusion>>
      <LimitsExclusionsManagement />
    </MgmtDrawerProvider>
  );
};
