import styles from "./html.utils.module.scss";

export const cleanTags = (str: string) => str.replace(/(<([^>]+)>)/gi, "");

export const adaptTextToHtml = (text: string) => {
  const functions = [addBreakLinesTags, replaceRootBullets, replaceIndentedBullets];
  return functions.reduce((result, func) => func(result), text);
};

const addBreakLinesTags = (input: string) => input.replaceAll(/\r\n/g, "<br />");

const replaceRootBullets = (input: string) => {
  const bulletChar = "•";
  return replaceBulletsWithLi(input, bulletChar);
};

const replaceIndentedBullets = (input: string) => {
  const bulletChar = "−";
  return replaceBulletsWithLi(input, bulletChar);
};

const replaceBulletsWithLi = (input: string, bulletChar: string) => {
  const items = input.split(new RegExp(bulletChar));
  if (items.length === 1) return input;
  const initialText = items.shift()?.trim();
  const listItems = items.map(item => `<li>${item.trim()}</li>`).join("");
  return `${initialText}<ul class="${
    bulletChar === "−" ? styles.dashed : undefined
  }">${listItems}</ul>`;
};
