import { Button, Grid } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { useAddLimitExclusion } from "../../../hooks/useLimitExclusions";
import { LimitExclusion } from "../../../types/brochure";
import { LimitExclusionForm } from "../base/LimitExclusionForm";
import { useAppStore } from "../../../session/store";
import { useMgmtDrawer } from "../../../context/MgmtDrawerContext";
import { AddAnotherCheckbox } from "../fields/AddAnotherCheckbox";
import { useMemo } from "react";
import { objNullsToStrings } from "../../../utils/models.utils";

export const AddLimitExclusion = () => {
  const { toggleMgmtDrawer, addAnother, selectedRow } = useMgmtDrawer();
  const defaultValues = useMemo(() => {
    if (!selectedRow) return {};
    const { id, ...rest } = selectedRow.row;
    return objNullsToStrings(rest);
  }, [selectedRow]);
  const methods = useForm<LimitExclusion>({ defaultValues });
  const { reset, handleSubmit, control } = methods;
  const { mutate: addLimitExclusion } = useAddLimitExclusion();
  const showAlert = useAppStore(state => state.showAlert);

  const onSuccess = () => {
    showAlert("Limit / Exclusion added successfully", "success");
    if (addAnother) return;
    reset({
      input: "",
      node: "",
      name: "",
      text: "",
      conditional_mappings: [],
    });
    toggleMgmtDrawer();
  };
  const onError = () => showAlert("Adding Limit / Exclusion failed", "error");
  const onSubmit = handleSubmit(data => addLimitExclusion(data, { onSuccess, onError }));

  return (
    <>
      <h3>Add Limitation / Exclusion Variable</h3>
      <Grid container spacing={2}>
        <FormProvider {...methods}>
          <LimitExclusionForm control={control} />
        </FormProvider>
        <AddAnotherCheckbox />
        <Grid item xs={12}>
          <Button type="submit" variant="contained" onClick={onSubmit}>
            Add
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
