import { Button, Grid } from "@mui/material";
import { GridRowParams } from "@mui/x-data-grid";
import { FormProvider, useForm, useFormState } from "react-hook-form";
import { useMgmtDrawer } from "../../../context/MgmtDrawerContext";
import { useUpdateBenefit, useUpdateBenefitBatch } from "../../../hooks/useBenefits";
import { useAppStore } from "../../../session/store";
import { Benefit } from "../../../types/brochure";
import { objNullsToStrings } from "../../../utils/models.utils";
import { bodyForBatchRequest } from "../../../utils/request.utils";
import { SaveConfirmDialog } from "../../dialogs/SaveConfirmDialog";
import { BenefitForm } from "../base/BenefitForm";
import { EditStateSelect } from "../fields/EditStateSelect";

export const EditBenefit = ({ selectedRow }: { selectedRow: GridRowParams<Benefit> | null }) => {
  const methods = useForm<Benefit>({
    defaultValues: objNullsToStrings(selectedRow?.row ?? {}),
  });
  const { control, handleSubmit } = methods;
  const { dirtyFields } = useFormState({ control });
  const { toggleMgmtDrawer, checkedStates, toggleSaveConfirmDialog, saveFieldsMode } =
    useMgmtDrawer();
  const { mutate: updateBenefit } = useUpdateBenefit();
  const { mutate: updateBenefitBatch } = useUpdateBenefitBatch();
  const showAlert = useAppStore(state => state.showAlert);

  const onSuccess = () => {
    showAlert("Benefit updated successfully", "success");
    toggleMgmtDrawer();
  };
  const onError = () => showAlert("Benefit update failed", "error");
  const onSubmit = handleSubmit(data => {
    if (checkedStates.length === 1) {
      updateBenefit(data, { onSuccess, onError });
    } else {
      const batchBody = bodyForBatchRequest(data, checkedStates, saveFieldsMode, dirtyFields);
      updateBenefitBatch(batchBody, { onSuccess, onError });
    }
  });

  return (
    <>
      <h3>Edit Benefit Variable</h3>
      <Grid container spacing={2}>
        <FormProvider {...methods}>
          <BenefitForm control={control} />
        </FormProvider>
        <Grid item xs={12}>
          <EditStateSelect />
        </Grid>
        <Grid item xs={12}>
          <div style={{ display: "flex", gap: 10, justifyContent: "space-between" }}>
            <Button
              type="submit"
              variant="contained"
              onClick={() => {
                if (checkedStates.length === 1) return onSubmit();
                toggleSaveConfirmDialog();
              }}
              disabled={!selectedRow}
            >
              Save
            </Button>
          </div>
        </Grid>
      </Grid>
      <SaveConfirmDialog handleSave={onSubmit} control={control} />
    </>
  );
};
