import { Button, Grid, Link } from "@mui/material";
import { useForm, useWatch } from "react-hook-form";
import { useAppStore } from "../../session/store";
import { Brochure } from "../../types/brochure";
import { BrochureForm } from "../forms/base/BrochureForm";
import { getBrochureRepoUrl } from "../../utils/urls.utils";
import { cleanTags } from "../../utils/html.utils";
import { usePreviewDrawerContext } from "../../context/PreviewDrawerContext";
import styles from "./Previews.module.scss";

export const OrderData = () => {
  const appStore = useAppStore();
  const { generateBrochureInput, updateGenerateBrochureInput } = appStore;
  const { changePreviewTab } = usePreviewDrawerContext();
  const { control } = useForm<Brochure>({
    defaultValues: {
      payload: generateBrochureInput,
    },
  });
  const inputVal = useWatch({ control, name: "payload" });
  const onSubmit = () => {
    updateGenerateBrochureInput(inputVal);
    cleanTags(inputVal) !== "" && changePreviewTab(0);
  };

  const brochureRepoUrl = getBrochureRepoUrl();

  return (
    <Grid container spacing={3}>
      <Grid item xs={2}>
        <Button type="submit" variant="contained" onClick={onSubmit}>
          Save & Preview
        </Button>
      </Grid>
      <Grid item xs={10} className={styles.link}>
        You can access EPS data from the <Link href={brochureRepoUrl}>Brochure Repository</Link>
      </Grid>
      <br />
      <BrochureForm control={control} />
    </Grid>
  );
};
