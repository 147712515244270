import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAppStore } from "../session/store";
import API from "../services/api";
import { General, Product, State } from "../types/brochure";
import { BatchRequest, DuplicateRequest } from "../types/api";

const QUERY_KEY_LIST_GENERALS = "listGenerals";

const getListGenerals = async (product?: Product, state?: State) => {
  if (!product?.id || !state?.id) {
    return Promise.resolve([]);
  }
  return API.services.getListGenerals(state, product);
};

export const useListGenerals = () => {
  const appStore = useAppStore();
  const { product, state } = appStore;
  return useQuery<General[], Error>(
    [QUERY_KEY_LIST_GENERALS, product?.id, state?.id],
    () => getListGenerals(product, state),
    {
      enabled: !!product?.id && !!state?.id,
    },
  );
};

export const useAddGeneral = () => {
  const appStore = useAppStore();
  const { product, state } = appStore;
  const queryClient = useQueryClient();
  return useMutation<General, Error, General>(
    (body: General) =>
      API.services.postGeneral({ ...body, product_id: product?.id, state_id: state?.id }),
    {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_LIST_GENERALS, product?.id, state?.id],
        });
      },
    },
  );
};

export const useUpdateGeneral = () => {
  const appStore = useAppStore();
  const { product, state } = appStore;
  const queryClient = useQueryClient();
  return useMutation<General, Error, General>(
    (body: General) =>
      API.services.putGeneral({ ...body, product_id: product?.id, state_id: state?.id }),
    {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_LIST_GENERALS, product?.id, state?.id],
        });
      },
    },
  );
};

export const useUpdateGeneralBatch = () => {
  const appStore = useAppStore();
  const { product, state } = appStore;
  const queryClient = useQueryClient();
  return useMutation<General, Error, BatchRequest<General>>(
    ({ body, states }) =>
      API.services.postGeneralBatch(
        { ...body, product_id: product?.id, state_id: state?.id },
        states,
      ),
    {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_LIST_GENERALS, product?.id],
        });
      },
    },
  );
};

export const useDeleteGeneral = () => {
  const appStore = useAppStore();
  const { product, state } = appStore;
  const queryClient = useQueryClient();
  return useMutation<General, Error, General>(
    (body: General) => API.services.deleteGeneral(Number(body.id)),
    {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_LIST_GENERALS, product?.id, state?.id],
        });
      },
    },
  );
};

const duplicateGenerals = async ({ product, fromState, toStates }: DuplicateRequest) => {
  if (!product?.id || !fromState?.id) {
    return Promise.resolve(undefined);
  }
  return API.services.duplicateGenerals(fromState, toStates, product);
};

export const useDuplicateGenerals = () => {
  const { product } = useAppStore();
  const queryClient = useQueryClient();
  return useMutation<General | undefined, Error, DuplicateRequest>(req => duplicateGenerals(req), {
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_LIST_GENERALS, product?.id],
      });
    },
  });
};
