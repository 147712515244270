import { useAuth0 } from "@auth0/auth0-react";
import { createContext, PropsWithChildren, useCallback, useEffect, useState } from "react";
import HttpClient from "../services/httpClient";

type AuthExtProps = {
  authorized: boolean;
};

type AuthContextProps = AuthExtProps;

export const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

type AuthProviderProps = PropsWithChildren<object>;

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [authorized, setAuthorized] = useState(false);
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const handleAuth = useCallback(() => {
    const fetchToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        HttpClient.token = token;
        setAuthorized(true);
      } catch (error) {
        console.log("error", error);
      }
    };
    if (isAuthenticated) fetchToken();
  }, [getAccessTokenSilently, isAuthenticated]);

  useEffect(handleAuth, [handleAuth]);
  return <AuthContext.Provider value={{ authorized }}>{children}</AuthContext.Provider>;
};

export const AuthConsumer = AuthContext.Consumer;
