import { Button, Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useMgmtDrawer } from "../../../context/MgmtDrawerContext";
import { useUpdateConditionalMapping } from "../../../hooks/useConditionalMappings";
import { useAppStore } from "../../../session/store";
import { ConditionalMapping as ConditionalMappingModel } from "../../../types/brochure";
import { SaveConfirmDialog } from "../../dialogs/SaveConfirmDialog";
import { ConditionalMappingForm } from "../base/ConditionalMappingForm";

export const EditConditionalMapping = () => {
  const { control, handleSubmit } = useFormContext<ConditionalMappingModel>();
  const { toggleMgmtDrawer } = useMgmtDrawer();
  const { mutate: updateConditionalMapping } = useUpdateConditionalMapping();
  const showAlert = useAppStore(state => state.showAlert);

  const onSuccess = () => {
    toggleMgmtDrawer();
    showAlert("ConditionalMapping updated successfully", "success");
  };
  const onError = () => {
    showAlert("ConditionalMapping update failed", "error");
  };
  const onSubmit = handleSubmit(data => {
    updateConditionalMapping(data, { onSuccess, onError });
  });

  return (
    <>
      <h3>Edit Conditional Mapping</h3>
      <Grid container spacing={2}>
        <ConditionalMappingForm />
        <Grid item xs={12}>
          <div style={{ display: "flex", gap: 10, justifyContent: "space-between" }}>
            <Button type="submit" variant="contained" onClick={() => onSubmit()}>
              Save
            </Button>
          </div>
        </Grid>
      </Grid>
      <SaveConfirmDialog handleSave={onSubmit} control={control} />
    </>
  );
};
