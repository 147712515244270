import { Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { conditionalMappingTypes } from "../../../constants";
import { ConditionalMapping } from "../../../types/brochure";
import { ControlledSelectField } from "../fields/ControlledSelectField";
import { ControlledTextField } from "../fields/ControlledTextField";
import { ConditionalMappingValuesForm } from "./ConditionalMappingValuesForm";

export const ConditionalMappingForm = () => {
  const { control } = useFormContext<ConditionalMapping>();
  return (
    <>
      <Grid item xs={12}>
        <ControlledTextField field="name" control={control} />
      </Grid>
      <Grid item xs={12}>
        <ControlledSelectField field="type" control={control} options={conditionalMappingTypes} />
      </Grid>
      <Grid item xs={12}>
        <ConditionalMappingValuesForm control={control} />
      </Grid>
    </>
  );
};
