import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import { useCallback } from "react";
import { Control, useWatch } from "react-hook-form";
import { ConditionalMapping, RowConditionalMapping } from "../../../types/brochure";
import { TypeWithConditionalMappings } from "./ConditionalMappingOptions";
import { ControlledTextField } from "./ControlledTextField";
import { SuffixMappingFields } from "./SuffixMappingFields";
import { TieredMappingFields } from "./TieredMappingFields";

export const ConditionalMappingAccordion = ({
  index,
  remove,
  mapping,
  control,
  conditionalMappingFormVals,
}: {
  index: number;
  remove: () => void;
  mapping?: ConditionalMapping;
  control: Control<TypeWithConditionalMappings>;
  conditionalMappingFormVals?: RowConditionalMapping[];
}) => {
  const fields = useWatch({ name: "conditional_mappings", control });
  const rowMapping = fields?.[index];

  const validate = useCallback(
    (value: string) => {
      let res: boolean | string = true;
      fields?.forEach((field, i) => {
        if (i === index) return;
        if (field.reference_var === value) {
          res = "Reference variable must be unique";
          return;
        }
      });
      return res;
    },
    [fields, index],
  );

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {mapping?.name}
            <Typography variant="subtitle2" color="gray">
              {rowMapping?.reference_var}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {mapping?.type === "suffix" && (
              <SuffixMappingFields
                index={index}
                control={control}
                mapping={mapping}
                conditionalMappingFormVals={conditionalMappingFormVals}
              />
            )}
            {mapping?.type === "tiered" && (
              <TieredMappingFields index={index} control={control} mapping={mapping} />
            )}
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              fieldLabel="Reference Variable"
              field={`conditional_mappings.${index}.reference_var`}
              control={control}
              helperText="Use this variable to reference the result of this conditional mapping."
              rules={{
                required: "Reference variable is required",
                pattern: {
                  value: /^\[cm_[a-zA-Z0-9_]+\]$/,
                  message:
                    "Reference variable must use letters, numbers, and underscores surrounded by '[cm_' and ']'. Example [cm_variable_2]",
                },
                validate,
              }}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
      <AccordionActions>
        <Button onClick={remove} color="error">
          Remove
        </Button>
      </AccordionActions>
    </Accordion>
  );
};
