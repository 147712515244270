import { FormControlLabel, Grid, Switch } from "@mui/material";
import { Control } from "react-hook-form";
import { Product } from "../../../types/brochure";
import { ControlledTextField } from "../fields/ControlledTextField";
import { ControlledSwitchField } from "../fields/ControlledSwitchField";

export const ProductForm = ({ control }: { control: Control<Product> }) => {
  return (
    <>
      <Grid item xs={12}>
        <ControlledTextField field="name" control={control} />
        <ControlledSwitchField field="ftp" control={control} fieldLabel="Enable FTP" />
      </Grid>
    </>
  );
};
