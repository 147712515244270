import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import API from "../services/api";
import { ConditionalMapping } from "../types/brochure";

const QUERY_KEY_LIST = "listConditionalMappings";

export const useListConditionalMappings = () => {
  return useQuery<ConditionalMapping[], Error>(
    [QUERY_KEY_LIST],
    API.services.getConditionalMappings,
  );
};

export const useAddConditionalMapping = () => {
  const queryClient = useQueryClient();
  return useMutation<ConditionalMapping, Error, ConditionalMapping>(
    (body: ConditionalMapping) => API.services.postConditionalMapping(body),
    {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_LIST],
        });
      },
    },
  );
};

export const useUpdateConditionalMapping = () => {
  const queryClient = useQueryClient();
  return useMutation<ConditionalMapping, Error, ConditionalMapping>(
    (body: ConditionalMapping) => API.services.putConditionalMapping(body),
    {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_LIST],
        });
      },
    },
  );
};

export const useDeleteConditionalMapping = () => {
  const queryClient = useQueryClient();
  return useMutation<ConditionalMapping, Error, ConditionalMapping>(
    (body: ConditionalMapping) => API.services.deleteConditionalMapping(Number(body.id)),
    {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_LIST],
        });
      },
    },
  );
};
