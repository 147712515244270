export const fabSx = {
  position: "absolute",
  bottom: 16,
  right: 16,
};

export const drawerSx = {
  "& .MuiDrawer-paper": { boxSizing: "border-box", width: 500, padding: "80px 40px 40px" },
};

export const previewDrawerSx = {
  "& .MuiDrawer-paper": {
    boxSizing: "border-box",
    width: "1200px",
    padding: "80px 40px 40px",
  },
};
