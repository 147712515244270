import { GridRowParams } from "@mui/x-data-grid-pro";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { State } from "../types/brochure";
import { useAppStore } from "../session/store";
import { SaveFieldsMode, UpdateModMode } from "../types/drawer";

interface MgmtDrawerContextValue {
  isOpen: boolean;
  modMode: "add" | "edit";
  selectedRow: GridRowParams<any> | null;
  saveType: "one" | "many";
  saveStates: State[];
  dupStates: State[];
  saveConfirmIsOpen: boolean;
  deleteConfirmIsOpen: boolean;
  saveFieldsMode: SaveFieldsMode;
  checkedStates: State[];
  dupConfirmIsOpen: boolean;
  addAnother: boolean;
  toggleMgmtDrawer: () => void;
  updateModMode: (mode: UpdateModMode) => void;
  updateSelectedRow: (row: GridRowParams<any> | null) => void;
  updateSaveType: (type: "one" | "many") => void;
  updateSaveStates: (states: State[]) => void;
  updateDupStates: (states: State[]) => void;
  toggleSaveConfirmDialog: () => void;
  toggleDeleteConfirmDialog: () => void;
  updateSaveFieldsMode: (mode: SaveFieldsMode) => void;
  toggleDupConfirmDialog: () => void;
  updateAddAnother: (addAnother: boolean) => void;
}

export const MgmtDrawerContext = createContext<MgmtDrawerContextValue | undefined>(undefined);

export const MgmtDrawerProvider = <T extends object>({ children }: PropsWithChildren) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modMode, setModMode] = useState<"add" | "edit">("edit");
  const [selectedRow, setSelectedRow] = useState<GridRowParams<T> | null>(null);
  const [saveType, setSaveType] = useState<"one" | "many">("one");
  const [saveStates, setSaveStates] = useState<State[]>([]);
  const [dupStates, setDupStates] = useState<State[]>([]);
  const [saveConfirmIsOpen, setSaveConfirmIsOpen] = useState(false);
  const [deleteConfirmIsOpen, setDeleteConfirmIsOpen] = useState(false);
  const [saveFieldsMode, setSaveFieldsMode] = useState<SaveFieldsMode>("edited");
  const [dupConfirmIsOpen, setDupConfirmIsOpen] = useState(false);
  const [addAnother, setAddAnother] = useState(false);
  const currentState = useAppStore(state => state.state);

  const toggleMgmtDrawer = useCallback(() => {
    setIsOpen(!isOpen);
    if (!isOpen) setSaveStates([]);
  }, [isOpen]);

  const updateModMode = useCallback(
    (mode: "add" | "edit") => {
      setModMode(mode);
    },
    [setModMode],
  );

  const updateSelectedRow = useCallback(
    (row: GridRowParams<T> | null) => {
      setSelectedRow(row);
    },
    [setSelectedRow],
  );

  const updateSaveType = useCallback(
    (type: "one" | "many") => {
      setSaveType(type);
    },
    [setSaveType],
  );

  const updateSaveStates = useCallback(
    (states: State[]) => {
      setSaveStates(states);
    },
    [setSaveStates],
  );

  const updateDupStates = useCallback(
    (states: State[]) => {
      setDupStates(states);
    },
    [setDupStates],
  );

  const toggleSaveConfirmDialog = useCallback(() => {
    setSaveConfirmIsOpen(!saveConfirmIsOpen);
  }, [saveConfirmIsOpen]);

  const updateSaveFieldsMode = useCallback(
    (mode: SaveFieldsMode) => {
      setSaveFieldsMode(mode);
    },
    [setSaveFieldsMode],
  );

  const checkedStates = useMemo(
    () => [...saveStates, ...(currentState ? [currentState] : [])].sort((a, b) => a.id - b.id),
    [saveStates, currentState],
  );

  const toggleDupConfirmDialog = useCallback(() => {
    if (!dupConfirmIsOpen) setDupStates([]);
    setDupConfirmIsOpen(!dupConfirmIsOpen);
  }, [dupConfirmIsOpen]);

  const toggleDeleteConfirmDialog = useCallback(() => {
    setDeleteConfirmIsOpen(!deleteConfirmIsOpen);
  }, [deleteConfirmIsOpen]);

  const updateAddAnother = useCallback(
    (addAnother: boolean) => {
      setAddAnother(addAnother);
    },
    [setAddAnother],
  );

  const contextValue: MgmtDrawerContextValue = useMemo(
    () => ({
      isOpen,
      modMode,
      selectedRow,
      saveType,
      saveStates,
      dupStates,
      saveConfirmIsOpen,
      deleteConfirmIsOpen,
      saveFieldsMode,
      checkedStates,
      dupConfirmIsOpen,
      addAnother,
      toggleMgmtDrawer,
      updateModMode,
      updateSelectedRow,
      updateSaveType,
      updateSaveStates,
      updateDupStates,
      toggleSaveConfirmDialog,
      toggleDeleteConfirmDialog,
      updateSaveFieldsMode,
      toggleDupConfirmDialog,
      updateAddAnother,
    }),
    [
      isOpen,
      modMode,
      selectedRow,
      saveType,
      saveStates,
      dupStates,
      saveConfirmIsOpen,
      deleteConfirmIsOpen,
      saveFieldsMode,
      checkedStates,
      dupConfirmIsOpen,
      addAnother,
      toggleMgmtDrawer,
      updateModMode,
      updateSelectedRow,
      updateSaveType,
      updateSaveStates,
      updateDupStates,
      toggleSaveConfirmDialog,
      toggleDeleteConfirmDialog,
      updateSaveFieldsMode,
      toggleDupConfirmDialog,
      updateAddAnother,
    ],
  );

  return <MgmtDrawerContext.Provider value={contextValue}>{children}</MgmtDrawerContext.Provider>;
};

export const useMgmtDrawer = () => {
  const context = useContext(MgmtDrawerContext);
  if (context === undefined) {
    throw new Error("useMgmtDrawer must be used within a MgmtDrawerProvider");
  }
  return context;
};
