import { Add as AddIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  Fab,
  Grid,
} from "@mui/material";
import { DataGrid, GridRowModel, GridRowParams, GridToolbar } from "@mui/x-data-grid";
import { useState } from "react";
import { drawerSx, fabSx } from "../components/constants";
import { MgmtDrawerProvider, useMgmtDrawer } from "../context/MgmtDrawerContext";
import { getColumns } from "../components/datagrids/ProductColumns";
import { AddProduct } from "../components/forms/add/AddProduct";
import { EditProduct } from "../components/forms/edit/EditProduct";
import { Header } from "../components/pages/Header";
import { useDeleteProduct, useListProducts } from "../hooks/useProducts";
import { Product } from "../types/brochure";
import { useAppStore } from "../session/store";

export const ProductsManagement = () => {
  const { data: rows, isLoading } = useListProducts();
  const { mutate: deleteProduct } = useDeleteProduct();
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const { isOpen, modMode, selectedRow, toggleMgmtDrawer, updateModMode, updateSelectedRow } =
    useMgmtDrawer();
  const showAlert = useAppStore(state => state.showAlert);

  const deleteOnSuccess = () => showAlert("Product deleted", "success");
  const deleteOnError = () => showAlert("Product delete failed", "error");

  const handleEditClick = (params: GridRowParams<Product>) => {
    updateSelectedRow(params);
    toggleMgmtDrawer();
    updateModMode("edit");
  };

  const handleAddClick = () => {
    toggleMgmtDrawer();
    updateModMode("add");
  };

  const handleDeleteClick = (params: GridRowParams<Product>) => {
    updateSelectedRow(params);
    setDeleteConfirmOpen(!deleteConfirmOpen);
  };

  const handleConfirmDelete = () => {
    if (selectedRow) {
      deleteProduct(selectedRow.row, { onSuccess: deleteOnSuccess, onError: deleteOnError });
      updateSelectedRow(null);
    }
    setDeleteConfirmOpen(false);
  };

  const columns = getColumns(handleEditClick, handleDeleteClick);

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3} justifyContent="center">
          <Header title="Products" />
          <Grid item xs={12} md={12} sx={{ minHeight: 350, width: "100%" }}>
            <DataGrid
              rows={rows ? (rows as GridRowModel<Product>[]) : []}
              columns={columns}
              loading={isLoading}
              slots={{ toolbar: GridToolbar }}
            />
          </Grid>
        </Grid>
      </Box>
      <Drawer anchor="right" open={isOpen} onClose={toggleMgmtDrawer} sx={drawerSx}>
        {modMode === "add" ? <AddProduct /> : <EditProduct selectedRow={selectedRow} />}
      </Drawer>
      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(!deleteConfirmOpen)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete {selectedRow?.row.name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(!deleteConfirmOpen)} autoFocus>
            Cancel
          </Button>
          <Button type="button" color="error" variant="contained" onClick={handleConfirmDelete}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Fab color="primary" aria-label="add" sx={fabSx} onClick={handleAddClick}>
        <AddIcon />
      </Fab>
    </div>
  );
};

export const Products = () => {
  return (
    <MgmtDrawerProvider<Product>>
      <ProductsManagement />
    </MgmtDrawerProvider>
  );
};
