import { GridRowOrderChangeParams, GridRowParams } from "@mui/x-data-grid-pro";
import { useMgmtDrawer } from "../context/MgmtDrawerContext";

type Props = {
  reorderFn: (params: {
    body: Record<string, unknown>;
    oldIndex: number;
    targetIndex: number;
  }) => void;
};

export const useEventHandlers = <T extends Record<string, unknown>>(props?: Props) => {
  const { reorderFn } = props || {};
  const { toggleMgmtDrawer, updateModMode, updateSelectedRow, toggleDeleteConfirmDialog } =
    useMgmtDrawer();

  const handleEditClick = (params: GridRowParams<T>) => {
    updateSelectedRow(params);
    toggleMgmtDrawer();
    updateModMode("edit");
  };

  const handleAddClick = () => {
    updateSelectedRow(null);
    toggleMgmtDrawer();
    updateModMode("add");
  };

  const handleDupRowClick = (params: GridRowParams<T>) => {
    updateSelectedRow(params);
    toggleMgmtDrawer();
    updateModMode("add");
  };

  const handleDeleteClick = (params: GridRowParams<T>) => {
    updateSelectedRow(params);
    toggleDeleteConfirmDialog();
  };

  const handleRowOrderChange = (params: GridRowOrderChangeParams) => {
    const { row: body, oldIndex, targetIndex } = params;
    reorderFn?.({ body, oldIndex, targetIndex });
  };

  return {
    handleEditClick,
    handleAddClick,
    handleDupRowClick,
    handleDeleteClick,
    handleRowOrderChange,
  };
};
