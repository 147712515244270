import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
} from "@mui/material";
import { ConditionalMapping, RowConditionalMapping } from "../../../types/brochure";
import { ControlledTextField } from "./ControlledTextField";
import { TypeWithConditionalMappings } from "./ConditionalMappingOptions";
import { Control } from "react-hook-form";

export const SuffixMappingFields = ({
  index,
  control,
  mapping,
  conditionalMappingFormVals,
}: {
  index: number;
  control: Control<TypeWithConditionalMappings>;
  mapping?: ConditionalMapping;
  conditionalMappingFormVals?: RowConditionalMapping[];
}) => {
  const extractValues = (value?: string) =>
    Array.from(value?.matchAll(/input_([^_]+)_value/g) ?? []).map(match => match[1]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ControlledTextField
          fieldLabel="Input"
          field={`conditional_mappings.${index}.input`}
          control={control}
          rules={{
            required: "Input is required",
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Condition</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mapping?.values?.map(val => (
                <TableRow
                  key={val.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {val.name}
                  </TableCell>
                  <TableCell>
                    {extractValues(conditionalMappingFormVals?.[index]?.input)
                      .map(v => `${v}${val.type_value}`)
                      .join(", ")}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};
