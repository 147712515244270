import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

interface PreviewDrawerContextValue {
  previewDrawerOpen: boolean;
  previewTab: number;
  togglePreviewDrawer: () => void;
  changePreviewTab: (newValue: number) => void;
}

const PreviewDrawerContext = createContext<PreviewDrawerContextValue | undefined>(undefined);

export const PreviewDrawerProvider = ({ children }: PropsWithChildren) => {
  const [previewDrawerOpen, setPreviewDrawerOpen] = useState(false);
  const [previewTab, setPreviewTab] = useState(0);

  const togglePreviewDrawer = useCallback(() => {
    setPreviewDrawerOpen(!previewDrawerOpen);
  }, [previewDrawerOpen]);

  const changePreviewTab = (newValue: number) => {
    setPreviewTab(newValue);
  };

  const contextValue: PreviewDrawerContextValue = useMemo(
    () => ({
      previewDrawerOpen,
      previewTab,
      togglePreviewDrawer,
      changePreviewTab,
    }),
    [previewDrawerOpen, previewTab, togglePreviewDrawer],
  );

  return (
    <PreviewDrawerContext.Provider value={contextValue}>{children}</PreviewDrawerContext.Provider>
  );
};

export const usePreviewDrawerContext = () => {
  const context = useContext(PreviewDrawerContext);
  if (context === undefined) {
    throw new Error("usePreviewDrawerContext must be used within a PreviewDrawerProvider");
  }
  return context;
};
