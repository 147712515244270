import { Button, Grid } from "@mui/material";
import { GridRowParams } from "@mui/x-data-grid";
import { useForm } from "react-hook-form";
import { useUpdateProduct } from "../../../hooks/useProducts";
import { Product } from "../../../types/brochure";
import { ProductForm } from "../base/ProductForm";
import { objNullsToStrings } from "../../../utils/models.utils";
import { useAppStore } from "../../../session/store";
import { useMgmtDrawer } from "../../../context/MgmtDrawerContext";

export const EditProduct = ({ selectedRow }: { selectedRow: GridRowParams<Product> | null }) => {
  const { handleSubmit, control } = useForm<Product>({
    defaultValues: objNullsToStrings(selectedRow?.row ?? {}),
  });
  const { mutate: updateProduct } = useUpdateProduct();
  const showAlert = useAppStore(state => state.showAlert);
  const { toggleMgmtDrawer } = useMgmtDrawer();

  const onSuccess = () => {
    showAlert("Product updated successfully", "success");
    toggleMgmtDrawer();
  };
  const onError = () => showAlert("Product update failed", "error");
  const onSubmit = handleSubmit(data => updateProduct(data, { onSuccess, onError }));

  return (
    <>
      <h3>Edit Product Variable</h3>
      <Grid container spacing={2}>
        <ProductForm control={control} />
        <Grid item xs={12}>
          <div style={{ display: "flex", gap: 10, justifyContent: "space-between" }}>
            <Button type="submit" variant="contained" onClick={onSubmit} disabled={!selectedRow}>
              Save
            </Button>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
