import { useQueryClient } from "@tanstack/react-query";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useCopyProductStatus } from "../hooks/useTools";
import { useAppStore } from "../session/store";
import { CopyProductRequest, JobStatus, JobStatusRequestKeys } from "../types/api";

export type ToolsMode = "copyProduct" | "restore";
interface ToolsContextValue {
  mode?: ToolsMode;
  dialogOpen: boolean;
  jobStatusRequestKeys?: JobStatusRequestKeys;
  setMode: (mode: ToolsMode) => void;
  toggleDialog: () => void;
  setJobStatusRequestKeys: (keys: JobStatusRequestKeys) => void;
}

const ToolsContext = createContext<ToolsContextValue | undefined>(undefined);

export const ToolsProvider = ({ children }: PropsWithChildren) => {
  const showAlert = useAppStore(state => state.showAlert);
  const queryClient = useQueryClient();
  const [mode, setMode] = useState<ToolsMode>();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [jobStatusRequestKeys, setJobStatusRequestKeys] = useState<JobStatusRequestKeys>();

  const toggleDialog = useCallback(() => {
    setDialogOpen(!dialogOpen);
  }, [dialogOpen]);

  const jobStatusOnSuccess = useMemo(
    () => (data: JobStatus) => {
      if (data?.status === "completed") {
        showAlert("Products copied successfully", "success");
      }
      if (data?.status === "failed") {
        showAlert(data?.errorLog ?? "An error occurred", "error");
        setJobStatusRequestKeys(undefined);
      }
    },
    [queryClient],
  );
  const jobStatusOnError = () => {
    showAlert("An error occurred during Copy Products operation", "error");
    setJobStatusRequestKeys(undefined);
  };
  useCopyProductStatus({
    requestKeys: jobStatusRequestKeys,
    onSuccess: jobStatusOnSuccess,
    onError: jobStatusOnError,
  });

  const contextValue: ToolsContextValue = useMemo(
    () => ({
      mode,
      dialogOpen,
      jobStatusRequestKeys,
      toggleDialog,
      setMode,
      setJobStatusRequestKeys,
    }),
    [dialogOpen, mode, toggleDialog, setMode],
  );

  return <ToolsContext.Provider value={contextValue}>{children}</ToolsContext.Provider>;
};

export const useToolsContext = () => {
  const context = useContext(ToolsContext);
  if (context === undefined) {
    throw new Error("useToolsContext must be used within a ToolsProvider");
  }
  return context;
};
