import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useMemo } from "react";
import { Control, useFormState, useWatch } from "react-hook-form";
import { useMgmtDrawer } from "../../context/MgmtDrawerContext";
import { isSaveFieldsMode } from "../../utils/validation.utils";
import { useListConditionalMappings } from "../../hooks/useConditionalMappings";

type Props = {
  handleSave: () => void;
  control: Control<any>;
};

export const SaveConfirmDialog = ({ handleSave, control }: Props) => {
  const {
    toggleSaveConfirmDialog,
    saveConfirmIsOpen,
    saveFieldsMode,
    updateSaveFieldsMode,
    selectedRow,
    checkedStates,
  } = useMgmtDrawer();
  const { dirtyFields } = useFormState({ control });
  const fields = useWatch({ control });
  const { data: mappings } = useListConditionalMappings();

  const onClose = () => {
    toggleSaveConfirmDialog();
  };

  const fieldsToUpdate = useMemo(() => {
    if (saveFieldsMode === "edited") return Object.keys(dirtyFields);
    return Object.keys(selectedRow?.row).filter(
      key => !["product_id", "state_id", "id", "order"].includes(key) && fields[key] !== "",
    );
  }, [saveFieldsMode, selectedRow?.row, dirtyFields, fields]);

  return (
    <Dialog
      open={saveConfirmIsOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">Confirm Save</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <span>States</span>
          <ul>
            {checkedStates.map(state => (
              <li key={state.id}>{state.name}</li>
            ))}
          </ul>
        </DialogContentText>
        <hr />
        <br />
        <FormControl fullWidth>
          <InputLabel id="save-mode-select-label">Fields to Update</InputLabel>
          <Select
            labelId="save-mode-select-label"
            id="save-mode-select"
            value={saveFieldsMode}
            label="Fields to Update"
            onChange={e => {
              if (isSaveFieldsMode(e.target.value)) updateSaveFieldsMode(e.target.value);
            }}
          >
            <MenuItem value="edited">Edited</MenuItem>
            <MenuItem value="all">All</MenuItem>
          </Select>
        </FormControl>
        <TableContainer component={Paper}>
          <Table aria-label="fields">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Field</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fieldsToUpdate.map((field: string) => (
                <TableRow key={field} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {field}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {field === "conditional_mappings"
                      ? mappings
                          ?.filter(mapping => fields[field]?.[0]?.conditional_id === mapping.id)
                          .map(mapping => <span key={mapping.id}>{mapping.name}</span>)
                      : fields[field]}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => {
            handleSave();
            toggleSaveConfirmDialog();
          }}
          autoFocus
          disabled={fieldsToUpdate.length === 0}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
