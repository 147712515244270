import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { clientsArr, dev, prd } from "../config/clients";
import { Config } from "../types/config";

export type { Config } from "../types/config";

export const useConfig = () => {
  const location = useLocation();
  const [config, setConfig] = useState<Config>();

  const checkConfig = useCallback(() => {
    const evalConfig = (altConfig: Config, altCid?: string, save = false) => {
      const checkConfig = clientsArr.find(({ cid }) => cid === altCid);
      const newConfig = checkConfig?.cid === altCid ? checkConfig : altConfig;
      if (save && newConfig?.cid) {
        window.localStorage.setItem("cid", JSON.stringify(newConfig.cid));
      }
      setConfig(newConfig);
    };

    const [subDomain] = window.location.hostname.split(".");
    const isPrd = subDomain === "brochures";

    if (isPrd) return evalConfig(prd, subDomain);
    const checkCid = window.localStorage.getItem("cid");
    const cid = checkCid ? JSON.parse(checkCid) : "";
    const qCid = new URLSearchParams(location.search).get("cid");
    evalConfig(dev, qCid || cid, true);
  }, [location.search]);

  useEffect(checkConfig, [checkConfig]);

  return { config };
};
