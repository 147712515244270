import { Grid } from "@mui/material";
import { useEffect } from "react";
import { Control, useFormContext } from "react-hook-form";
import { ConditionalMapping } from "../../../types/brochure";
import { TypeWithConditionalMappings } from "./ConditionalMappingOptions";
import { ControlledTextField } from "./ControlledTextField";

export const TieredMappingFields = ({
  index,
  control,
  mapping,
}: {
  index: number;
  control: Control<TypeWithConditionalMappings>;
  mapping?: ConditionalMapping;
}) => {
  const { setValue, getValues } = useFormContext<TypeWithConditionalMappings>();

  useEffect(() => {
    mapping?.values?.forEach((val, idx) => {
      const value = getValues(`conditional_mappings.${index}.values.${idx}.mapping_value_id`);
      if (value !== undefined || !val.id) return;
      setValue(`conditional_mappings.${index}.values.${idx}.mapping_value_id`, val.id);
    });
  }, [mapping, setValue, index]);

  return (
    <Grid container spacing={2}>
      {mapping?.values?.map((val, idx) => (
        <Grid item xs={12}>
          <ControlledTextField
            fieldLabel={`${val.name}`}
            key={`${val.id}-input`}
            field={`conditional_mappings.${index}.values.${idx}.input`}
            control={control}
          />
        </Grid>
      ))}
    </Grid>
  );
};
