import { Box, Grid } from "@mui/material";
import { GridPaginationModel, GridRowParams, GridToolbar } from "@mui/x-data-grid-pro";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getColumns } from "../components/datagrids/LogColumns";
import { PersistedDataGrid } from "../components/datagrids/PersistedDataGrid";
import { RestoreBackupDialog } from "../components/dialogs/RestoreBackupDialog";
import { Header } from "../components/pages/Header";
import { useLogCount, useLogs } from "../hooks/useLogs";
import { Log } from "../types/brochure";

export const Logs = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedLog, setSelectedLog] = useState<Log>();

  const handleRestoreClick = (row: GridRowParams<Log>) => {
    setSelectedLog(row.row);
    setDialogOpen(true);
  };
  const columns = getColumns(handleRestoreClick);

  const paginationModel = useMemo(
    () => ({
      page: searchParams.get("page") ? Number(searchParams.get("page")) : 0,
      pageSize: searchParams.get("pageSize") ? Number(searchParams.get("pageSize")) : 100,
    }),
    [searchParams],
  );

  const handlePaginationModelChange = useCallback(
    (newModel: GridPaginationModel) => {
      setSearchParams({
        page: newModel.page.toString(),
        pageSize: newModel.pageSize.toString(),
      });
    },
    [setSearchParams],
  );

  const { data: rows, isLoading: loadingLogs } = useLogs({
    limit: paginationModel.pageSize.toString(),
    offset: (paginationModel.pageSize * paginationModel.page).toString(),
  });

  const { data: logCount, isLoading: loadingLogCount } = useLogCount();

  const [rowCountState, setRowCountState] = useState(logCount?.count ?? 0);

  useEffect(() => {
    setRowCountState(prevRowCountState =>
      logCount !== undefined ? logCount.count : prevRowCountState,
    );
  }, [logCount, logCount?.count, setRowCountState]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3} justifyContent="center">
        <Header title="Logs" />
        <Grid item xs={12} md={12} sx={{ minHeight: 350, width: "100%" }}>
          <PersistedDataGrid
            name="logs"
            rows={rows ?? []}
            columns={columns}
            loading={loadingLogs || loadingLogCount}
            slots={{ toolbar: GridToolbar }}
            initialState={{ pinnedColumns: { right: ["actions"] } }}
            getRowHeight={() => "auto"}
            pagination
            paginationMode="server"
            paginationModel={paginationModel}
            pageSizeOptions={[10, 20, 50, 100]}
            onPaginationModelChange={handlePaginationModelChange}
            rowCount={rowCountState}
            rowSelection={false}
          />
        </Grid>
      </Grid>
      <RestoreBackupDialog
        open={dialogOpen}
        log={selectedLog}
        toggleDialog={() => setDialogOpen(!dialogOpen)}
      />
    </Box>
  );
};
