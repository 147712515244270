import { TableContainer, Paper, Table, TableBody, TableRow, TableCell } from "@mui/material";
import { OrderInfo } from "../../types/brochure";
import { camelCaseToWords } from "../../utils/forms.utils";

export const InfoTable = ({ orderInfo }: { orderInfo?: OrderInfo }) => {
  if (!orderInfo) return null;
  return (
    <TableContainer component={Paper}>
      <Table sx={{ width: "100%" }} size="small" aria-label="Order Information">
        <TableBody>
          {Object.entries(orderInfo).map(([key, value]) => (
            <TableRow key={key} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell width={130} align="right">
                {camelCaseToWords(key)}
              </TableCell>
              <TableCell>{value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
