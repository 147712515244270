import { Grid } from "@mui/material";
import { Control, useWatch } from "react-hook-form";
import { Benefit } from "../../../types/brochure";
import { ControlledTextField } from "../fields/ControlledTextField";
import { QuillTextField } from "../fields/QuillTextField";
import { ConditionalMappingOptions } from "../fields/ConditionalMappingOptions";

export const BenefitForm = ({ control }: { control: Control<Benefit> }) => {
  const node = useWatch({ control, name: "node" });

  // Return null to prevent rendering the Quill editors prior to getting their values,
  // since we cannot reinitialize their configuration after the first render.
  if (!!control._defaultValues.id && !node) return null;

  const allowFormatting = node !== "Benefit Header" && node !== "Rider Header";

  return (
    <>
      <Grid item xs={12}>
        <ControlledTextField field="input" control={control} />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField field="node" control={control} />
      </Grid>
      <Grid item xs={12}>
        {allowFormatting ? (
          <QuillTextField label="Title" field="title" control={control} />
        ) : (
          <ControlledTextField field="title" control={control} />
        )}
      </Grid>
      <Grid item xs={12}>
        <QuillTextField label="Subtitle" field="subtitle" control={control} />
      </Grid>
      <Grid item xs={12}>
        <QuillTextField label="Value" field="value" control={control} />
      </Grid>
      <Grid item xs={12}>
        <QuillTextField label="Description" field="description" control={control} />
      </Grid>
      <Grid item xs={12}>
        <ConditionalMappingOptions control={control} />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField field="days" control={control} />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField field="days_reference" control={control} />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField field="months" control={control} />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField field="months_reference" control={control} />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField field="years" control={control} />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField field="years_reference" control={control} />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField field="amount" control={control} />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField field="amount_reference" control={control} />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField field="start_time" control={control} />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField field="start_time_reference" control={control} />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField field="end_time" control={control} />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField field="end_time_reference" control={control} />
      </Grid>
    </>
  );
};
